const Static_globals = {
    Helmet_page_title: "GeWis Passion for Quality",
    Helmet_page_content: "GeWis Group",
    Helmet_href: "https://www.GeWis.in/",
    Bot_welcome_message: "Welcome to GeWis. How can I help you!!",
    queryUrl: "https://uniwev.com/gewis/getResponse",
    videoSrc: "../assets/images/Erito_Welcome.mp4",
    //baani_poster: "../assets/images/baani.png",
};
module.exports = Static_globals;
