import styled from "styled-components";

const Content = styled.div`
    height: calc(
        ${(props) => props.height} -
            ${(props) => (props.hideInput ? "56px" : "290px")}
    );
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
    padding-bottom: 40px;
    position: relative;

    @media screen and (max-width: 568px) {
        height: ${(props) => (props.floating ? "calc(100% - 290px)" : "")};
    }
`;

export default Content;
