import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonials = [
    {
        image: require("./../../images/testimonials/pic1.jpg"),
        reviewername: "GeWiS client1",
        position: "Business Person",
        review:
            "Nothing worthwhile is ever achieved without deep thought and hard work.Good human relations not only bring great personal rewards but are essential to the success of any enterprise.",
    },
    {
        image: require("./../../images/testimonials/pic2.jpg"),
        reviewername: "GeWiS client2",
        position: "Contractor",
        review:
            "One must think for oneself and never accept at their face value, slogans and catch phrases to which, unfortunately, our people are too easily susceptible.",
    },
    {
        image: require("./../../images/testimonials/pic3.jpg"),
        reviewername: "GeWiS client3",
        position: "Builder",
        review:
            "No success or achievement in material terms is worthwhile unless it serves the needs, or interests of the country and its people and is achieved by fair and honest means.",
    },
];

var bnr1 = require("./../../images/background/bg6.jpg");

class Testimonials extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            nav: false,
            dots: true,
            navText: [
                '<i class="fa fa-angle-left"></i>',
                '<i class="fa fa-angle-right"></i>',
            ],
            responsive: {
                0: {
                    items: 1,
                },
                991: {
                    items: 2,
                },
            },
        };

        return (
            <>
                <div
                    className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover"
                    style={{ backgroundImage: "url(" + bnr1 + ")" }}
                >
                    {/* <div className="container" style={{margin:'auto'}}> */}

                        <div className="section-content">
                       
                            {/* <div className="container" style={{margin:'auto' , textAlign:'center'}}> */}
                            {/* TITLE START */}                        
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one ">
                                            <span className="font-weight-300 text-gewis">
                                                Client
                                            </span>{" "}
                                            testimonials
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            {/* </div>                        */}
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <div className="container" style={{margin:"auto"}}>
                            <OwlCarousel
                                className="owl-carousel testimonial-home"
                                {...options}
                            >
                                {testimonials.map((item, index) => (
                                    
                                    <div key={index} className="item">
                                        
                                        <div className="testimonial-2 m-a30 hover-animation-1">
                                       
                                            <div className=" block-shadow bg-white p-a30">
                                            
                                                <div className="testimonial-detail clearfix">
                                                    <div className="testimonial-pic radius shadow scale-in-center">
                                                        <img
                                                            src={item.image}
                                                            width={100}
                                                            height={100}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <h4 className="testimonial-name m-b5">
                                                        {item.reviewername} -
                                                    </h4>
                                                    <span className="testimonial-position">
                                                        {item.position}
                                                    </span>
                                                </div>
                                                <div className="testimonial-text">
                                                    <span className="fa fa-quote-right" />
                                                    <p> {item.review}</p>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Testimonials;
