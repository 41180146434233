import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import '../styles.css';

var bnrimg = require('./../../images/banner/autobanner.png');

var mecion = require('./../../images/projects/square/me-cion.png');
var right_arrow = require('./../../images/projects/square/right-arrow.png');
var requirement_analysis = require('./../../images/projects/square/requirement-analysis.png');
var arrow_2 = require('./../../images/projects/square/2-arrow.png');
var down_arrow = require('./../../images/projects/square/down-arrow.png');
var left_arrow = require('./../../images/projects/square/left-arrow.png');
var ipresentawtion_to_customermg = require('./../../images/projects/square/presentawtion-to-customer.png');
var project_planning = require('./../../images/projects/square/project-planning.png');
var system_permance_monitoring = require('./../../images/projects/square/system-permance-monitoring.png');
var best_proposal_making = require('./../../images/projects/square/best-proposal-making.png');
var installation_comision = require('./../../images/projects/square/installation-comision.png');
var order_receipt_customer = require('./../../images/projects/square/order-receipt-customer.png');
var automation_why_1 = require('./../../images/projects/square/automation-why-1.png');
var automation_why_2 = require('./../../images/projects/square/automation-why-2.png');
var automation_why_3 = require('./../../images/projects/square/automation-why-3.png');
var automation_why_4 = require('./../../images/projects/square/automation-why-4.png');
var automation_chart = require('./../../images/projects/square/automation-chart.png');


const projects = [
    {
        image: require('./../../images/projects/square/robotic-automation.png'),
        title: 'Robotic Automation',
        description: 'Robotic cell layout design',
        description1: 'Gripper (EOAT)selection / design',
        description2: 'Pallet / conveyor design',
        description3: 'Robot Woking simulation on software',
    },
    {
        image: require('./../../images/projects/square/gantry-automation.png'),
        title: 'Gantry Automation',
        description: 'Gantry cell layout design',
        description1: 'Gripper (EOAT)selection / design',
        description2: 'Pallet / conveyor design',
        description3: 'Gantry Woking simulation on software.',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/projects/square/customize-automation.png'),
        title: 'Customize Automation',
        description: 'Customized solution design.',
        description1: 'Economical solution with pneumatics & PLC',
        description2: 'Complete feasibility study with software simulation',
        description3: '',
    },
    {
        image: require('./../../images/projects/square/elctronic-interfacing.png'),
        title: 'Electronic Interfacing',
        description: 'Complete panel design with all components',
        description1: 'PLC / CNC controls selection',
        description2: 'All field devices selection',
        description3: 'Panel wiring & Complete integration on field',
    }//,
    // {
    //     image: require('./../../images/projects/square/pic3.jpg'),
    //     title: 'Unique solutions for your home through a personalized process',
    //     description: 'Morbi mattis ex non urna condim entum, eget eleifend diam molestie. Curabitur lorem enim'
    // }
]

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Automation frees up human potential and ignites creativity by eliminating mundane tasks" pagename="Automation" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">


                            <div className="section-content">

                                <div className="container" style={{ marginBottom: '90px', display: 'flex', alignItems: "center",  justifyContent: "center"}}>
                                            <h3 className="blacktext" style={{textAlign: 'center', fontWeight:'normal'}}>
                                                <strong>Process flow – Automation</strong>
                                            </h3>
                                </div>

                               
                                <div className="container">

                                    <div className="box">
                                        <p><img  src={mecion} alt="" width="180" height="180"  sizes="(max-width: 180px) 100vw, 180px"></img></p>
                                        <p><h5>RFQ receipt from customer</h5></p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone size-full wp-image-284" src={arrow_2} alt="" width="113" height="146"></img></div>
                                    </div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-289" src={requirement_analysis}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                    <p>Requirement analysis</p>
                                    </div>
                                    <div className="box">
                                        <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div></div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-298" src={best_proposal_making} alt="" width="170" height="170" sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Best Proposal making</p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div>
                                    </div>
                                    <div className="box">
                                        <p><img class="alignnone size-full wp-image-300" src={ipresentawtion_to_customermg} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Presentation to customer</p></div>
                                </div>
                                  <div className="container">
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"><div class="second"><img class="alignnone arrow_down size-full wp-image-301" src={down_arrow} alt="" width="70" height="113"></img></div></div>
                                </div>  
                                <div className="container">
                                <div className="box">
                                        <p><img  src={system_permance_monitoring} alt="" width="180" height="180"  sizes="(max-width: 180px) 100vw, 180px"></img></p>
                                        <p><h5>RFQ receipt from customer</h5></p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone size-full wp-image-284" src={left_arrow} alt="" width="113" height="146"></img></div>
                                    </div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-289" src={installation_comision}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                    <p>Requirement analysis</p>
                                    </div>
                                    <div className="box">
                                        <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={left_arrow} alt="" width="113" height="69"></img></div></div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-298" src={project_planning} alt="" width="170" height="170" sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Best Proposal making</p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={left_arrow} alt="" width="113" height="69"></img></div>
                                    </div>
                                    <div className="box">
                                        <p><img class="alignnone size-full wp-image-300" src={order_receipt_customer} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Presentation to customer</p></div>
                                </div>

                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                    
                                        {projects.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={""}>{item.title}</NavLink></h4>
                                                    <ul>
                                                        <li>{item.description}</li>
                                                        <li>{item.description1}</li>
                                                        <li>{item.description2}</li>
                                                        <li>{item.description3}</li>
                                                    </ul>
                                                    {/* <NavLink to={"/project-detail"} className="site-button btn-effect">Read More</NavLink> */}
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                                 

                                        <div className='container' style={{ margin:'auto' , textAlign:'center' ,marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                            <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Why US</h2>
                                        </div>
                                   

                                            <div className='container' style={{ margin: 'auto', marginTop: '0px' }}>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6">
                                                <div className=" " style={{ alignItems: 'center', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', marginRight: '10px', height: '250px' }}>
                                                    <img src={automation_why_1} alt="" style={{ width: '65%', height: '65%', marginLeft: '35px' }}></img>
                                                    <p style={{ margin: '0 10px', marginLeft: '20px', textAlign: 'center' }}>Dedicated team of Mechanical & Electrical designers & project managers for each project.</p>
                                                </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">

                                                <div className=" " style={{ alignItems: 'center', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', marginRight: '10px', height: '250px' }}>
                                                    <img src={automation_why_2} alt="" style={{ width: '65%', height: '65%', marginLeft: '35px', marginTop: '0px' }}></img>
                                                    <p style={{ margin: '0 10px', marginLeft: '20px', textAlign: 'center' }}>World-class software for mechanical system design & simulation.</p>
                                                </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">

                                                <div className=" " style={{ alignItems: 'center', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', marginRight: '10px', height: '250px' }}>
                                                    <img src={automation_why_3} alt="" style={{ width: '65%', height: '65%', marginLeft: '35px', marginTop: '10px' }}></img>
                                                    <p style={{ margin: '0 10px', marginLeft: '20px', textAlign: 'center' }}>World-class software for electrical system design.</p>
                                                </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                <div className=" " style={{ alignItems: 'center', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', height: '250px' }}>
                                                    <img src={automation_why_4} alt="" style={{ width: '65%', height: '65%', marginLeft: '35px', marginTop: '10px' }}></img>
                                                    <p style={{ margin: '0 10px', marginLeft: '20px', textAlign: 'center' }}>Dedicated PLC programming software.</p>
                                                </div>
                                                </div>
                                            </div>
                                            </div>

                                            <div className='container' style={{ margin:'auto' , textAlign:'center' , marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                                <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Organization structure</h2>
                                            </div>

                                            <div className="container" style={{ fontWeight: 'normal',margin:'auto' , textAlign:'left' }}>
                                            <img src={automation_chart}></img>
                                            </div> 
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectCarousel;