import React from "react";
import PropTypes from "prop-types";
import defaultTheme from "../../theme";

const OptionElement = ({ value, label, onChange }) => {
    return (
        <label
            style={{
                display: "inline-flex",
                alignItems: "center",
                marginRight: "16px",
                marginBottom: "4px",
                fontSize: "11px",
                color: "#4577AE",
                cursor: "pointer",
            }}
        >
            <input
                type="radio"
                name="option"
                value={value}
                onChange={onChange}
                style={{ opacity: 0, position: "absolute" }}
            />
            <span
                style={{
                    position: "relative",
                    height: "16px",
                    width: "16px",
                    border: "2px solid #4577AE",
                    borderRadius: "50%",
                    marginRight: "8px",
                    backgroundColor: "transparent",
                }}
            ></span>
            {label}
        </label>
    );
};

OptionElement.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

OptionElement.defaultProps = {
    theme: defaultTheme,
};

export default OptionElement;
