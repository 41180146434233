import React from "react";
import { NavLink } from "react-router-dom";

const services = [
    {
        count: 1,
        title: `Automation`,
        icon: require("./../../images/icon/AutomationIndustry.png"),
        description: (
            <ul>
                {" "}
                <li></li>
                <li>- Processed Automation</li>
                <li>- Customized Automation</li>
                <li>- Electronic interfacing </li>
                <li>- PLC & VFD Sell </li>
                <li></li>
                <li></li>
            </ul>
        ),
        NavLink:'/Service_Automation',
    },
    {
        count: 2,
        title: "Software Services",
        icon: require("./../../images/icon/ITServices.png"),
        description: (
            <ul>
                {" "}
                <li>- Consulting</li>
                <li>- Development & Customization</li>
                <li>- Implementation</li>
                <li>- User Training</li>
                <li>- Maintenance</li>
                <li>- Customer Support</li>
            </ul>
        ),
        NavLink:'/Service_Software',
    },
    {
        count: 3,
        title: "Industry 4.0",
        icon: require("./../../images/icon/I40.png"),
        description: (
            <ul>
                {" "}
                <li> - Production Machine Monitoring Solutions for Machines with PLC/ CNC.</li>
                <li> - Machine communication with Ethernet & HMI for data input by operator.</li>
                <li>
                    {" "}
                    - Web dashboard / Android app for data representation.

                </li>             
                <li></li>
            </ul>
        ),
        NavLink:'/Service_Software',
    },
    {
        count: 4,
        title: "ShouldB",
        icon: require("./../../images/icon/BusinessGrowth.png"),
        description: (
            <ul>
                {" "}
                <li>
                    A part manufacturing time and cost estimation and process
                    workflow management tool designed by manufacturing
                    engineers.
                </li>
                <ul>
                    <li> - Workout by our ME team</li>
                    <li>
                        {" "}
                        - Process planning, Cycle time calculation & part
                        costing
                    </li>
                    <li> - Cutting tool selection</li>
                    <li> - Process capability monitoring</li>
                    <li> - Installation & prove out on machine</li>
                    <li> - CNC Programming</li>
                    <li> - Fixture design</li>
                </ul>
            </ul>
        ),
        NavLink:'/Service_Software',
    },
    
    {
        count: 5,
        title: "Manufacturing Engineering",
        icon: require("./../../images/icon/EngineeringAutomation.png"),
        description: (
            <ul>
                {" "}
                <li> - Customer Support</li>
                <li> - Workout by our ME team</li>
                <li>
                    {" "}
                    - Process planning, Cycle time calculation & part costing
                </li>
                <li> - Cutting tool selection</li>
                <li> - Process capability monitoring</li>
                <li> - Installation & prove out on machine</li>
                <li> - CNC Programming</li>
                <li> - Fixture design</li>
                <li></li>
            </ul>
        ),
        NavLink:'/Service_Manufacturing',
    },



];

var img1 = require("./../../images/background/bg-6.png");

class OurServices extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function(resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function() {
                    resolve();
                });
                script.addEventListener("error", function(e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/masonary.js");
    }
    render() {
        return (
            <>
                 <div className="section-full mobile-page-padding  p-b50  square_shape2">        
                    <div className="section-content">                        
                        <div className="Service-half-top p-t80  bg-gewis bg-moving">
                            <div className="container" style={{margin:'auto' , textAlign:'center'}}>
                                {/* TITLE START */}
                                <div className="section-head text-white">
                                    <div className="mt-separator-outer separator-center">
                                        <div className="mt-separator">
                                            <h2 className="text-white text-uppercase sep-line-one ">
                                                <span className="font-weight-300 text-gewis">
                                                    Our
                                                </span>{" "}
                                                Services
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="section-head m-b10 text-white text-center">
                                        <h4>
                                            We provide support and solutions to
                                            our customers in the areas of
                                            automation, software services,
                                            production and mechanical design
                                        </h4>
                                    </div>
                                </div>
                                {/* TITLE END */}
                            </div>
                        </div>
                        <div className="services-half-bottom">
                            <div className="container" style={{margin:'auto'}}>
                                <div className="row">
                                    {services.map((item, index) => (
                                        <div
                                            className="col-lg-5 col-sm-6"
                                            key={index}
                                        >
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">
                                                        {item.count}
                                                    </span>
                                                    <div className="icon-md inline-icon m-b15 text-gewis scale-in-center">
                                                        <span className="icon-cell">
                                                            <img
                                                                src={item.icon}
                                                                alt=""
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b5">
                                                            {item.title}
                                                        </h4>
                                                        <p>NavLink
                                                            {item.description}
                                                        </p>
                                                        <NavLink
                                                            to={item.NavLink}
                                                            className="site-button-link"
                                                            data-hover="Read More"
                                                        >
                                                            Read More{" "}
                                                            <i className="fa fa-angle-right arrow-animation" />
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </>
        );
    }
}

export default OurServices;
