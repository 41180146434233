import React, { Component } from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from "styled-components";
import { isMobile } from "react-device-detect";
import baani_img from "../assets/images/baani_floating.png";

import ChatBot from "./ChatBot.jsx";
import Loading from "./steps_components/common/Loading.jsx";
import Cookies from "js-cookie";
import Random from "random-id";
const GlobalStepResponse = require("./GlobalStepResponse");
const Globals = require("./Globals");
let bot_step_options = "optionsStep";
let bot_step_optionMessage = "optionMessage";

let bot_step_search = "search";

let session_id = "";
let isOptionValue = 0;
let apiResponse = "...";
//let snd = "";
let snd = require("./snd");
let recognitionFlag = true;
let search_or_option_flag = "search";
const video_server = "https://uniwev.com/video/";
let proactive_intent_flag = 0;
let proactive_intent_name = "";

const theme = {
    background: "#EEF0EB",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#3B5F7C",
    headerFontColor: "#fff",
    headerFontSize: "14px",
    botBubbleColor: "#4577AE",
    botOptionsBubbleColor: "#5792D3",
    botOptionsBubbleColorHover: "#3B5F7C",
    botFontColor: "#fff",
    userBubbleColor: "#00FF7D",
    userFontColor: "#4a4a4a",
    controlStyle: { position: "relative" },
};

class CustomStepComponent extends Component {
    constructor(props) {
        console.log("-- Inside constructor");
        super(props);

        this.state = {
            loading: true,
            result: "",
            trigger: false,
        };

        this.triggetNext = this.triggetNext.bind(this);
    }

    UNSAFE_componentWillMount() {
        console.log("-- Inside UNSAFE_componentWillMount");
        const self = this;
        const { steps } = this.props;
        let search = steps.search.value;

        if (search_or_option_flag == "options") {
            // Ashok added this on 03-Jun-23
            // The below check confirms if the current user message is option but user submitted speech. In this case this will trigger a search instead of option confirmation
            if (Globals.SubmitOptionValue.trim()) {
                search = Globals.SubmitOptionValue;
                search_or_option_flag = "search";
                isOptionValue = 0;
            } else {
                //End of 03-June code

                search = steps.optionsStep.value;
                search_or_option_flag = "search";
                isOptionValue = 1;
            }
        } else {
            isOptionValue = 0;
        }
        //const session_id = Cookies.get("session_id");

        session_id = Cookies.get("session_id", { maxAge: 3600 });

        console.log("-- steps is: ", steps);
        console.log("-- session_id is: ", session_id);
        if (session_id === undefined) {
            console.log("-- session_id is undefined.");
            session_id = Random(24);
            Cookies.set("session_id", session_id, { expires: 14 });
        }

        /*const queryUrl =
        ("https://jyjskg0nu0.execute-api.ap-southeast-1.amazonaws.com/dev01/dialog"); */

        //const queryUrl = "http://52.66.93.152:2501/getResponse";
        const queryUrl = "https://uniwev.com/gewis/getResponse";

        const xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", readyStateChange);

        function readyStateChange() {
            console.log("-- this.readyState value is: ", this.readyState);

            if (this.readyState === 4) {
                console.log("-- responseText:", this.responseText);
                const response = JSON.parse(this.responseText);
                console.log("--Inside readyStateChange");
                console.log("-- response:", response);

                const data = response["body"].response;
                //const base64Audio = response["body"].testAudio;  //Commented on 15-Mar-23
                const video_path = response["body"].video_path;
                //GlobalStepResponse = response["body"].step_options;
                //GlobalStepResponse[1].label = "Modified label";
                GlobalStepResponse.options_flag = 0;
                Globals.Request_Intent_Description =
                    response["body"].intent_description;
                console.log(
                    "--Request_Intent_Description is: ",
                    Globals.Request_Intent_Description
                );
                Globals.Free_Text_Submitted_For_Option = 0; //Added by Ashok on 06-Jun-23
                console.log(
                    "--Inside readyStateChange Free_Text_Submitted_For_Option",
                    Globals.Free_Text_Submitted_For_Option
                );

                Globals.skipDisplayOptionFlag = 0; //Added by Ashok on 03-Jun-23

                if (response["body"].options_flag == 1) {
                    GlobalStepResponse.options_flag =
                        response["body"].options_flag;
                    GlobalStepResponse.options_message =
                        response["body"].response;
                    GlobalStepResponse.option_values.splice(
                        0,
                        GlobalStepResponse.option_values.length,
                        ...response["body"].step_options
                    );
                    console.log(
                        "---- GlobalStepResponse is :",
                        GlobalStepResponse
                    );

                    search_or_option_flag = "options";
                } else {
                    search_or_option_flag = "search";
                }

                if (response["body"].transition_flag == 1) {
                    console.log("XXXXXXXXXX Iteration 1 should be called");
                    GlobalStepResponse.transition_flag = 1;
                    GlobalStepResponse.transition_processed_flag = 0;

                    GlobalStepResponse.next_state.next_state_type =
                        response["body"].next_state.next_state_type;
                    GlobalStepResponse.next_state.next_state_response =
                        response["body"].next_state.next_state_response;

                    GlobalStepResponse.next_state.next_state_intent_name =
                        response["body"].next_state.next_state_intent_name;
                    GlobalStepResponse.next_state.next_state_optionValues.splice(
                        0,
                        GlobalStepResponse.next_state.next_state_optionValues
                            .length,
                        ...response["body"].next_state.next_state_optionValues
                    );
                    console.log(
                        "---- GlobalStepResponse is :",
                        GlobalStepResponse
                    );
                }

                console.log("-- video_path is :", video_path);
                console.log("-- GlobalStepResponse is :", GlobalStepResponse);
                apiResponse = data;
                console.log("-- data :", data);
                console.log(
                    "-- response[body].response :",
                    response["body"].response
                );
                //console.log('-- data.length :', data.length)
                if (data && data.length > 0) {
                    console.log("-- Assigning  value of Data:", data);
                    self.setState({ loading: false, result: data });
                } else {
                    self.setState({ loading: false, result: "Not found." });
                }

                if (video_path && video_path.length > 0) {
                    const video_source = video_server + video_path;
                    console.log("-- Video Server Path is :", video_source);

                    document.dispatchEvent(
                        new CustomEvent("custom", {
                            detail: { video_src: video_source },
                        })
                    );

                    document.dispatchEvent(
                        new CustomEvent("AudioPlay", {
                            detail: { video_src: video_source },
                        })
                    );

                    //snd.src = "data:audio/wav;base64," + base64Audio;
                    snd.src = video_source;
                    //snd.play();
                }
            }
        }

        if (GlobalStepResponse.transition_flag == 1) {
            search = GlobalStepResponse.next_state.next_state_response;
            proactive_intent_name =
                GlobalStepResponse.next_state.next_state_intent_name;
            GlobalStepResponse.transition_flag = 0;
            proactive_intent_flag = 1;
            //isOptionValue = 1;
        } else {
            proactive_intent_flag = 0;
            proactive_intent_name = "";
        }

        // Create API Request
        console.log("--Before Open");
        console.log("--Value of Search is:", search);
        console.log("--sessionId is: ", session_id);

        xhr.open("GET", queryUrl, false);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("validation-required", "allow");
        xhr.setRequestHeader("isOptionValue", isOptionValue);
        xhr.setRequestHeader("sessionId", session_id);
        xhr.setRequestHeader("userInput", search);
        xhr.setRequestHeader("proactive_intent_flag", proactive_intent_flag);
        xhr.setRequestHeader("proactive_intent_name", proactive_intent_name);
        xhr.setRequestHeader(
            "Request_Intent_Description",
            Globals.Request_Intent_Description
        );

        xhr.setRequestHeader(
            "Free_Text_Submitted_For_Option",
            Globals.Free_Text_Submitted_For_Option
        );

        xhr.send();
    }

    triggetNext() {
        console.log("--inside triggetNext");
        this.setState({ trigger: true }, () => {
            this.props.triggerNextStep();
        });
    }

    render() {
        console.log("--inside render of CustomStepComponent");
        const { trigger, loading, result } = this.state;
        return (
            <div className="CustomStepComponent">
                {loading ? <Loading /> : result}
                {!loading && (
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: 0,
                        }}
                    ></div>
                )}
            </div>
        );
    }
}

CustomStepComponent.propTypes = {
    steps: PropTypes.object,
    triggerNextStep: PropTypes.func,
};

CustomStepComponent.defaultProps = {
    steps: undefined,
    triggerNextStep: undefined,
};

class Baani extends Component {
    constructor(props) {
        super(props);
        console.log("-- inside baani Constructor");

        if (isMobile) {
            recognitionFlag = true;
        }
    }
    render() {
        console.log("-- inside baani render");
        return (
            <ThemeProvider theme={theme}>
                <div>
                    <ChatBot
                        headerTitle="GeWiS"
                        floatingIcon={baani_img}
                        customDelay={5}
                        botAvatar={baani_img}
                        //speechSynthesis={{ enable: true, lang: 'en-GB', rate: '.1' }}
                        floating={true}
                        opened={true}
                        enableSmoothScroll={true}
                        recognitionEnable={recognitionFlag}
                        // Below added on 17-May-22
                        enableMobileAutoFocus={false}
                        recognitionPlaceholder={"Listening"}
                        //userDelay={5000}
                        //headerComponent={baani_img}
                        steps={[
                            {
                                id: "start",
                                message:
                                    "Welcome to GeWiS. How can I help you!!",
                                trigger: bot_step_search,
                            },
                            {
                                id: bot_step_search,
                                user: true,
                                trigger: "getResult",
                                validator: (value) => {
                                    console.log("-- Inside Validator -----");
                                    if (snd == "") {
                                        snd = new Audio();
                                    }
                                    return true;
                                },
                            },
                            {
                                id: "getResult",
                                component: <CustomStepComponent />,
                                asMessage: true,
                                trigger: "search",
                            },
                            {
                                id: bot_step_optionMessage,
                                message: "Please select one option",
                                trigger: bot_step_options,
                            },
                            {
                                id: bot_step_options,
                                options: [
                                    {
                                        value: 1,
                                        label: "Number 1",
                                        trigger: bot_step_search,
                                    },
                                    {
                                        value: 2,
                                        label: "Number 2",
                                        trigger: bot_step_search,
                                    },
                                    {
                                        value: 3,
                                        label: "Number 3",
                                        trigger: bot_step_search,
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            </ThemeProvider>
        );
    }
}

export default Baani;
