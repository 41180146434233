import { isMobile } from "./utils";
import { isIphone } from "./utils";
const GlobaRecognitionValue = require("./GlobaRecognitionValue");

let instance = null;

const noop = () => {};

export default class Recognition {
    static isSupported() {
        console.log(
            "--XX-BBBB- Inside Recognition isSupported. Yes recognition supported. Returning the Webkit new webkit"
        );
        return new window.webkitSpeechRecognition();
    }

    constructor(onChange = noop, onEnd = noop, onStop = noop, lang = "en") {
        console.log("--XX-BBBB- Inside constructor.");

        if (!instance) {
            console.log(
                "--XX-BBBB- Inside constructor. No instance therefore setting instance to this"
            );
            instance = this;
        }
        this.state = {
            inputValue: "",
            lang,
            onChange,
            onEnd,
            onStop,
        };

        this.onResult = this.onResult.bind(this);
        this.onEnd = this.onEnd.bind(this);

        this.setup();

        return instance;
    }

    /*** Handler for recognition change event   */
    onChange(interimTranscript) {
        const { onChange } = this.state;
        this.setState({
            inputValue: interimTranscript,
        });

        console.log(
            "--XX-BBBB- Inside onChange. interimTranscript is: ",
            interimTranscript
        );

        onChange(interimTranscript);
    }

    /*** Handler for recognition end event */
    onEnd(event) {
        console.log(
            "--XX-BBBB- Inside onEnd. inputValue is: ",
            this.state.inputValue
        );

        GlobaRecognitionValue.RecognitionValue = this.state.inputValue;
        const { onStop, onEnd, force } = this.state;
        this.setState({ speaking: false });

        if (force) {
            onStop();
        }

        onEnd(event); //Added by Ashok on 09-Jun-23
        if (this.state.inputValue.trim()) {
            //Ashok added this check on 29-May-23
            //onEnd(event);   //Commented by Ashok on 09-Jun-23
        }
    }

    /*** Handler for recognition result event */
    onResult(event) {
        let interimTranscript = "";
        let finalTranscript = "";
        console.log(
            "--XX-BBBB- ????? Inside onResult function. event.results is: ",
            event.results
        );

        for (let i = event.resultIndex; i < event.results.length; i += 1) {
            console.log(
                "--XX-BBBB- Inside onResult. transcript is: ",
                event.results[i][0].transcript
            );
            console.log(
                "--XX-BBBB- Inside onResult. isFinal is: ",
                event.results[i].isFinal
            );

            interimTranscript += event.results[i][0].transcript;
            console.log(
                "--XX-BBBB- Inside onResult. interimTranscript is: ",
                interimTranscript
            );
            this.onChange(interimTranscript);
        }
    }

    /**
     * method for updating the instance state */
    setState(nextState) {
        this.state = Object.assign({}, this.state, nextState);
    }

    /**  setup the browser recognition */
    setup() {
        console.log("--XX-BBBB- Inside setup. ");

        if (!Recognition.isSupported()) {
            return this;
        }

        const { webkitSpeechRecognition } = window;
        this.recognition = new webkitSpeechRecognition();
        this.recognition.continuous = false;
        this.recognition.interimResults = true;
        this.recognition.lang = this.state.lang;
        this.recognition.onresult = this.onResult;
        this.recognition.onend = this.onEnd;
        return this;
    }

    /** change the recognition lang and resetup  */
    setLang(lang) {
        this.setState({ lang });
        this.setup();
        return this;
    }

    /** toggle the recognition*/
    speak() {
        this.setup();
        if (!Recognition.isSupported()) {
            console.log("--XX-BBBB- Inside speak. Recognition NOT SUPPORTED. ");
            return this;
        }
        const { speaking } = this.state;
        if (!speaking) {
            console.log(
                "--XX-BBBB- Inside speak. User not speaking. Starting recognition. "
            );
            this.recognition.start();
            this.setState({
                speaking: true,
                inputValue: "",
            });
        } else {
            this.setState({
                force: true,
            });

            console.log(
                "--XX-BBBB- Inside speak. User speaking. stopping recognition. "
            );
            this.recognition.stop();
        }
        return this;
    }

    //Ashok added this on 14-May-23
    stopRecognition() {
        console.log("--XX-BBBB- Inside stopRecognition.");
        //this.recognition.stop();
    }

    //Ashok added this on 14-May-23
    disposeRecognition() {
        console.log("--XX-BBBB- Inside stopRecognition.");
        //this.recognition = null;
    }

    //Ashok added this on 14-May-23
    disposeRecognition() {
        console.log("--XX-BBBB- Inside disposeRecognition.");
        if (this.recognition) {
            GlobaRecognitionValue.RecognitionValue = "";
            this.state.inputValue = "";

            this.recognition.start(); //Added by Ashok on 28-May-23
            this.recognition.abort();
            this.recognition = null;
        }
    }
}
