import React, { Component } from "react";
import baani_poster from "../assets/images/baani.png";

export var customEvent = new CustomEvent("custom", {
    detail: { video_src: "newVideo.mp4" },
});
export var videoEndEvent = new CustomEvent("VideoEnd", {
    detail: "video ended",
});

export default class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoSrc: "../assets/images/Erito_Welcome.mp4",
        };
        this.videoRef = React.createRef(); // Create a ref to the video element
    }

    handleVideoEnd = () => {
        console.log("-- VIDEO ENDED");
        this.setState({ videoSrc: "" }); // Update videoSrc in the state
        window.dispatchEvent(videoEndEvent);
    };

    handleNVFocus = (event, data) => {
        console.log("-- handleNVFocus CALLED");
        this.setState({ videoSrc: event.detail.video_src }, () => {
            // Call load() on video element to load the new source
            if (this.videoRef.current) {
                this.videoRef.current.load();
                this.videoRef.current.play();
            }
        });
    };

    componentDidMount() {
        document.addEventListener("custom", this.handleNVFocus);
    }

    componentWillUnmount() {
        document.removeEventListener("custom", this.handleNVFocus);
    }

    render() {
        console.log("-- Player render is called");
        console.log("-- video_src is: ", this.state.videoSrc);

        return (
            <div
                className="card border-0"
                id="videoInstance"
                style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <video
                    ref={this.videoRef} // Use the ref here
                    controls=""
                    muted
                    crossOrigin="anonymous"
                    width="100%"
                    height="100%"
                    poster={baani_poster}
                    onEnded={this.handleVideoEnd} // Add event listener for "ended" event
                >
                    <source src={this.state.videoSrc} type="video/mp4"></source>
                </video>
            </div>
        );
    }
}
