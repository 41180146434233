import React from "react";
import { NavLink } from "react-router-dom";
var Gewis_logo = require('./../../images/GeWis_logo.png');

class Navigation extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function(resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function() {
                    resolve();
                });
                script.addEventListener("error", function(e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/masonary.js");
    }

    render() {
        return (
            <>
          
          <div
                    className={
                        this.props.bgcolor !== ""
                            ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
                            : "header-nav navbar-collapse collapse"
                    } 
                >
                    <ul className="nav navbar-nav">
                        <li className="active">
                            <NavLink to={"/"}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/about"}>About us</NavLink>
                        </li>
                        <li>
                            <NavLink to={""}>Services</NavLink>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink to={"/Service_Automation"}>
                                        Automation
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/Service_Software"}>
                                        Software Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/Service_Manufacturing"}>
                                        Manufacturing Engineering
                                    </NavLink>
                                </li>
           
                            </ul>
                        </li>

                        <li>
                            <NavLink to={"/ContactUs"}>Contact Us</NavLink>
                            
                        </li>
                    </ul>
                </div>
               
            </>
        );
    }
}

export default Navigation;
