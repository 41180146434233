const GlobalStepResponse = {
    options_flag: 0,
    options_message: "",
    option_values: [
        { value: "1", label: "", trigger: "" },
        { value: "2", label: "", trigger: "" },
    ],
    transition_flag: 0,
    transition_processed_flag: 0,
    next_state: {
        next_state_type: 0,
        next_state_intent_name: "",
        next_state_response: "",
        next_state_optionValues: [
            { value: "1", label: "", trigger: "" },
            { value: "2", label: "", trigger: "" },
        ],
    },
};
module.exports = GlobalStepResponse;
