import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-6.png");

class Specialization3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-gewis mobile-page-padding">
                <div className="container" style={{margin:'auto' , textAlign:'center'}}>
                    <div className="section-content">

                   

                        {/* IMAGE CAROUSEL START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one ">
                                        <span className="font-weight-300 text-primary">
                                            Our
                                        </span>{" "}
                                        Projects
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="section-head m-b10 text-white text-center">
                                <h4 className="m-b10">
                                    GeWiS Engineering in Pune, India, provides
                                    support and solutions to us as well as our
                                    customers in the areas of automation,
                                    software services, production and mechanical
                                    design
                                </h4>
                            </div>
                        </div>

                    



                            <div className="row  m-t50" style={{ margin:'auto', maxWidth:'60vw',height:'auto'}}>
                            
                                <div className="col-md-6 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow" >
                                        <img
                                            src={require("../../images/services/1.jpg")} style={{width:'100%',height:'auto'}}
                                            alt=""
                                        />
                                        <div className="figcaption">
                                            <h4>Concept and Design</h4>
                                            <p>
                                                Project details to be added
                                                here.
                                            </p>
                                            <NavLink to="/">
                                                <i className="link-plus bg-primary" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow" >
                                        <img
                                            src={require("../../images/services/2.jpg")} style={{width:'100%',height:'auto'}}
                                            alt=""
                                        />
                                        <div className="figcaption">
                                            <h4>Refurbishment</h4>
                                            <p>
                                                Project details to be added
                                                here.
                                            </p>
                                            <NavLink to="/">
                                                <i className="link-plus bg-primary" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img
                                            src={require("../../images/services/3.jpg")} 
                                            alt=""
                                        />
                                        <div className="figcaption bg-dark">
                                            <h4>General Builder</h4>
                                            <p>
                                                Project details to be added
                                                here.
                                            </p>
                                            <NavLink to="/">
                                                <i className="link-plus bg-primary" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        
                    </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Specialization3;
