import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About4 from "./../Elements/About4";
import Specialization4 from "./../Elements/Specialization4";
import Team3 from "./../Elements/Team3";
import AboutSummary from "./../Elements/AboutSummary";

var bnrimg = require("./../../images/banner/aboutus.jpg");
var about1 = require('./../../images/projects/square/about1.png');
var member1 = require('./../../images/projects/square/member1.png');
var history1 = require('./../../images/projects/square/history1.jpg');
var history2 = require('./../../images/projects/square/history2.jpg');
var history3 = require('./../../images/projects/square/history3.jpg');
var history4 = require('./../../images/projects/square/about1-1.png');

class About extends React.Component {
    render() {
        return (
            <>
                <Header />  
              <div className="page-content">
                    <Banner
                        title="Creating quality urban lifestyles, building stronger communities."
                        pagename="About Us"
                        bgimage={bnrimg}
                    />
                    {/* <About4 />
                    <Specialization4 />
                    <Team3 />
                    <AboutSummary /> */}
                  <div className="section-full p-tb80 bg-gray inner-page-padding">
                    <div className="container-fluid">
                      <div className="section-content">

                                    <div className='container' style={{ margin:'auto' , textAlign:'center' , marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                            <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Our Guiding Principles</h2>
                                    </div>

                            <div className="container uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal',margin:'auto' , textAlign:'left' }}>
                                <ul>
                                
                                <li style={{margin:'auto' , textAlign: 'centre' }}> Nothing worthwhile is ever achieved without deep thought and hard work.</li>
                                <li style={{margin:'auto' , textAlign: 'centre' }}> One must think for oneself and never accept at their face value, slogans and catch phrases to which, unfortunately, our people are too easily susceptible.</li>  
                                <li style={{margin:'auto' , textAlign: 'centre' }}> One must for ever strive for excellence or even perfection in any task however small, and never be satisfied with the second best.</li>  
                                <li style={{margin:'auto' , textAlign: 'centre' }}> No success or achievement in material terms is worthwhile unless it serves the needs, or interests of the country and its people and is achieved by fair and honest means.</li> 
                                <li style={{margin:'auto' , textAlign: 'centre' }}> Good human relations not only bring great personal rewards but are essential to the success of any enterprise.</li> 
                                </ul>
                            </div>                    
                                        
                                
                            <div className='container' style={{ margin:'auto' , textAlign:'center' ,marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                            <h2 style={{ margin:'auto', fontWeight: 'bold' }}>About GeWiS Gruppe </h2>
                            </div>


                      <div className="container " style={{ margin: 'auto', textAlign: 'center', marginTop: '0px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="" style={{ width: '100%', margin: 'auto' }}>
                              <img src={about1} alt="" style={{ marginTop: '15px', width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px" />
                              <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal', marginLeft: '15px', marginTop: '20px', textAlign: 'left' }}>
                                <p>Members of the GeWiS group</p>
                                <ul style={{ marginLeft: '45px' }}>
                                  <li style={{ textAlign: 'left' }}>GeWiS Drehteilefertigungs GmbH</li>
                                  <li style={{ textAlign: 'left' }}>GeWiS Slovakia s.r.o.</li>
                                  <li style={{ textAlign: 'left' }}>GeWiS USA, Inc.</li>
                                  <li style={{ textAlign: 'left' }}>GeWiS Engineering India Pvt. Ltd.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="" style={{ textAlign: 'left', marginLeft: '15px' }}>
                                <div id="ultimate-heading-1151648699ccc9e14" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-1151648699ccc9e14 uvc-6552" data-hspacer="no_spacer" data-halign="center" style={{ textAlign: 'left' }}>
                                  <p style={{ fontWeight: 'normal' }}>Since its foundation in 1979, the GeWiS Group has established itself as a system supplier in the processing of precision and complex parts and manufactured Groups for the automotive industry, electric and hydraulics industry, and agricultural technology, with the motto Passion for Quality.
                                  Besides the GeWiS Drehteilefertigungs GmbH, that started all of this, also a part of the GeWiS group are two branches of GeWiS Slovakia s.r.o. Prievidza and Handlova. On 26.000 m2, we produce products of the highest quality in small, medium, and high volumes. Therefore, we keep our machines up to the current standard of technology and implement the requirement of an integrated management system. We have a competent partner for our customers in the U.S. in GeWiS USA, Inc. GeWiS Engineering in Pune, India, provides support and solutions to us as well as our customers in the areas of automation, software services, production, and mechanical design. Our goal is to fulfill the wishes of our customers at a high level. We happily accept new challenges. We grow with our projects.</p>
                                </div>
                                <div className="" style={{ textAlign: 'right', marginBottom:'10px' }}>
                                  <p><img className="alignnone size-full wp-image-289 image-containerx" src={member1} alt="" width="200" height="200" sizes="(max-width: 170px) 100vw, 170px" /></p>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>



                        <div className="container" style={{ margin: 'auto', textAlign: 'center', alignItems: 'center', marginTop: '20px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
                          <div className="row">
                                <div className="col-md-6 col-sm-12">
                                              <div className=" " style={{ textAlign: 'left', marginLeft: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <div style={{ fontSize: 'bold', MarginLeft: '-30px' }}>
                                                  <h2 style={{MarginLeft: '-30px' }}>History</h2>
                                                </div>
                                              </div>

                                      <div className="row">
                                        
                                                <div className=" " style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                  <img src={history1} alt="" width="270" height="270" style={{ maxWidth: '100%', border: '3px solid orange' }} />
                                                </div>
                                                <div className=" " style={{ marginTop: '10px', marginBottom: '30px' }}>
                                                  <img src={history2} alt="" width="270" height="270" style={{ maxWidth: '100%', border: '3px solid orange', marginTop:'50px' }} />
                                                </div>
                                        
                                      </div>      

                                      <div className="row">      
                                    
                                              
                                                <div className=" " style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                  <img src={history3} alt="" width="270" height="270" style={{ maxWidth: '100%',  border: '3px solid orange', marginBottom:'50px' }} />
                                                </div>
                                                <div className=" " style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                  <img src={history4} alt="" width="270" height="270" style={{ maxWidth: '100%',  border: '3px solid orange', marginBottom:'50px' }} />
                                                </div>
                                              
                                        
                                      </div> 
                                </div>          
                                <div className="col-md-6 col-sm-12">
                                              <div className="text-container" style={{ textAlign: 'left', marginLeft: '30px', marginTop:'100px'}}>
                                                <p><b>1979</b> Establishment of GeWiS</p>
                                                <p><b>1986</b> Starting production for the automotive industry</p>
                                                <p><b>1991</b> Establishment of GeWiS Slovakia s.r.o. in Zemianske Kostolany</p>
                                                <p><b>1994</b> Certification of the GeWiS Group</p>
                                                <p><b>1996</b> Relocation of GeWiS Slovakia s.r.o. to Handlova</p>
                                                <p><b>1997 - 2009</b> Taking over Max Schmid GmbH Antriebstechnik in Memmingen (Gearing)</p>
                                                <p><b>2000</b> Relocation of GeWiS Drehteilefertigungs GmbH to Adelsried (Administration & storage)</p>
                                                <p><b>2001</b> Purchase and expansion of production site in Handlova</p>
                                                <p><b>2007</b> Establishment of GeWiS USA, Inc.</p>
                                                <p><b>2008</b> Establishment of GeWiS Engineering India Pvt. Ltd.</p>
                                                <p><b>2009 - 2012</b> GeWiS Slovakia s. r. o. , Production site in Novaky</p>
                                                <p><b>2012</b> Start of production at the new headquarter of GeWiS Slovakia s. r. o. in Prievidza</p>
                                                <p><b>2013</b> Establishment of GeWiS Holding GmbH based in Adelsried</p>
                                                <p><b>2016</b> Solar EPC work in India</p>
                                                <p><b>2017</b> Started GeWiS Renewpower in India</p>

                                              </div>
                                </div>                                


                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

              </div>

                <Footer />
            </>
        );
    }
}

export default About;
