import React, { useRef } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var bnrimg = require('./../../images/banner/contactus.jpg');
const serviceId = "service_qq4mnmg";
const templateId = "template_vxa5lz7";
const userId = "ibd99mwapn2lVMB3s";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const ContactUs = () =>{
    
        const defaultProps = {
            center: {
              lat: 34.073280,
              lng: -118.251410
            },
            zoom: 12
        };
        const formRef = useRef();
        const sendEmail = async (e) => {
            e.preventDefault();
    
            try {           
                await emailjs.sendForm(serviceId, templateId, formRef.current, userId);
                const userName = formRef.current.user_name.value;
    
                // Show success toast
                toast.success(`Thank you ${userName}! We will contact you soon.`);
    
                // Reset the form
                formRef.current.reset();
            } catch (error) {
                console.log(error.text);
                // Show error toast
                toast.error('Something went wrong. Please try again.');
            }
        };
        return (
            <>
                <Header />
                <div className="page-content">
                {/* <div className="col-lg-12 col-md-8 col-sm-6"> */}
                                    <Banner title="Putting a plan to action, to assure your satisfaction!" pagename="Contact Us" bgimage={bnrimg}/>
                {/* </div> */}
                {/* <div className="gmap-outline">
                                <div style={{ height: '200px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        
                                    >
                                        <AnyReactComponent
                                        lat={34.073280}
                                        lng={-118.251410}
                                        text={<i className="fa fa-map-marker" />}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div> */}
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container" style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        <form className="contact-form cons-contact-form" ref={formRef} onSubmit={sendEmail}>
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="form-group">
                                                    <input name="username" type="text" required className="form-control" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" className="form-control" required placeholder="Email" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
                                                </div>


                                                <div>
                                                <p>
                                                    <strong>Privacy</strong>
                                                    <span class="wpcf7-form-control-wrap EU-DSGVO">
                                                    <span class="wpcf7-form-control wpcf7-acceptance">
                                                    <span class="wpcf7-list-item">
                                                    <label><input type="checkbox" name="EU-DSGVO" value="1" aria-invalid="false" style={{opacity:1}}/>
                                                    <span class="wpcf7-list-item-label">  I agree that my details from the contact form will be collected and processed to answer my request. The data is saved or deleted on request.
                                                    Note: You can revoke your consent at any time for the future by sending an e-mail to info@gewis.in. Detailed information on handling user data can be found in our <a href="https://gewis.in/privacy-policy/">privacy policy</a>.</span>
                                                    </label>
                                                    </span>
                                                    </span>
                                                    <span class="w-form-row-field-bar"></span>
                                                    </span>
                                                
                                                </p>
                                                </div>





                                                <div className="text-right">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(+91) 20-67122400</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>info@gewis.in</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>705-707, Nyati Emporius,Radha Chowk,Off Mumbai-Banglore Bypass,Baner, Pune-411045, India
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>Engineering services, Software development, Automation Design & Integration, Solar EPC</p>
                                                    </div>
                                                </div> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>
                <Footer />
                <ToastContainer />

            </>
        );
    
};

export default ContactUs;