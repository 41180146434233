import styled from "styled-components";

const VideoBox = styled.div`
    position: relative;
    //position: float;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
`;

export default VideoBox;
