import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import '../styles.css';

var bnrimg = require('./../../images/banner/softwareservices.jpg');

 var consulting = require('./../../images/projects/square/consulting.png');
var right_arrow = require('./../../images/projects/square/right-arrow.png');
var software_dev = require('./../../images/projects/square/software-dev.png');
var Customer_support = require('./../../images/projects/square/erp-chart-1.png');
var down_arrow = require('./../../images/projects/square/down-arrow.png');
var left_arrow = require('./../../images/projects/square/left-arrow.png');
var ipresentawtion_to_customermg = require('./../../images/projects/square/erp-chart-2.png');
var edi_chart = require('./../../images/projects/square/edi-chart.jpg');
var our_team = require('./../../images/projects/square/our-team.png');
var automation_icon = require('./../../images/projects/square/automation-icon.png');
var Maintenance = require('./../../images/projects/square/erp-chart-6.png');
var dev_cust = require('./../../images/projects/square/dev-cust.png');
var drill = require('./../../images/projects/square/erp-chart-3.png');
var consulting_2 = require('./../../images/projects/square/consulting-2.png');
var cnc_programming = require('./../../images/projects/square/erp-chart-4.png');
var up_arrow = require('./../../images/projects/square/up-arrow.png');
var sc_softs_2 = require('./../../images/projects/square/erp-chart-7.png');
var implementation = require('./../../images/projects/square/implementation.png');
var training_1 = require('./../../images/projects/square/training.png');
var training_2 = require('./../../images/projects/square/training-2.png');
var maintances = require('./../../images/projects/square/maintances.png');
var cust_support = require('./../../images/projects/square/cust-support.png');
var sc_softs_8 = require('./../../images/projects/square/erp-chart-5.png');
var manufacturing = require('./../../images/projects/square/manufacturing.png');
var warehouse = require('./../../images/projects/square/warehouse.png');
var automation = require('./../../images/projects/square/automation.png');
var renewal_energy= require('./../../images/projects/square/renewal-energy.png');




class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };


        
    





        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Unlock the power of innovation with our cutting-edge software services " pagename="Software Services" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                            <div className="section-content">
                            
                                    <div className="container" style={{ marginBottom: '90px', display: 'flex', alignItems: "center",  justifyContent: "center"}}>
                                                    <h3 className="blacktext" style={{textAlign: 'center', fontWeight:'normal'}}>
                                                        <strong>GeWiS Services -Infographic</strong>
                                                    </h3>
                                        </div>
                                    
                                        <div className="container ">
                                            <div className="box"></div>
                                            <div className="box">
                                            <p><img class="alignnone size-full wp-image-289" src={Customer_support}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Customer Support</p>
                                            </div>
                                            <div className="box">
                                                <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div></div>
                                            <div className="box">
                                            <p><img class="alignnone size-full wp-image-289" src={ipresentawtion_to_customermg}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Consulting </p>
                                            </div>
                                            
                                            <div className="box">
                                            <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div>
                                            </div>
                                            <div className="box">
                                                <p><img class="alignnone size-full wp-image-300" src={drill} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                                    <p>Development and Customization</p></div>
                                            <div className="box"></div>        
                                        </div>

                                        <div className="container ">
                                            <div className="box"></div>
                                            <div className="box" style={{textAlign:'center'}}><div class="second"><img class="alignnone arrow_up size-full wp-image-301" src={up_arrow} alt="" width="70" height="113"></img></div></div>
                                            <div className="box"></div>
                                            <div className=" "><p><img class="alignnone size-full wp-image-295" src={sc_softs_2}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p></div>
                                            <div className="box"></div>                                           
                                            <div className="box" style={{textAlign:'center'}}><div className='second'><img class="alignnone arrow_down size-full wp-image-301" src={down_arrow} alt="" width="70" height="113"></img></div></div>
                                            <div className="box"></div>
                                        </div>  


                                        <div className="container ">
                                            <div className="box"></div>
                                            <div className="box">
                                            <p><img class="alignnone size-full wp-image-289" src={Maintenance}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Maintenance </p> 
                                            </div>
                                            <div className="box"><div className='second'>
                                            <img class="alignnone size-full wp-image-298" src={left_arrow}  alt="" width="113" height="69"></img>
                                            </div></div>
                                            <div className="box">
                                            <p><img class="alignnone size-full wp-image-289" src={sc_softs_8}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>User Training</p>
                                            </div>
                                            <div className="box"><div className='second'>
                                            <img class="alignnone size-full wp-image-298" src={left_arrow}  alt="" width="113" height="69"></img>
                                            </div></div>
                                            <div className="box">
                                            <p><img class="alignnone size-full wp-image-289" src={cnc_programming}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Implementation </p>
                                            </div>
                                            <div className="box">
                                            </div>
                                        </div>
                                     
                                        
                                        <div style={{width: '100%', height:'10px', backgroundColor:'#FFFFFF', borderBottom: '5px solid gray', borderBottomWidth: 'thick'}}></div>

                                        <div className='container' style={{ margin:'auto' , textAlign:'center' , marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                                        <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Service Portfolio</h2>
                                                    </div>

                                        <div className='container' style={{ margin:'auto' , textAlign:'center' , marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                            
                                            <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <p style={{fontSize:'160%', alignItems: "left", justifyContent: "left", marginTop:'3px' }}> 
                                                            <strong>Software Development:</strong>
                                                        </p>
                                            
                                                        <p><img  src={software_dev}  alt="" style={{ width: '50%', height: '50%' }} sizes="(max-width: 120px) 100vw, 120px"></img></p>
                                                    </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="text-container" style={{textAlign: 'left', marginLeft: '90px', marginTop:'25px'}}>
                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                                <li style={{ textAlign: 'centre' }}> End-to-end software engineering services</li>
                                                                <li style={{ textAlign: 'centre' }}> Software initiatives in bringing new solutions to the market</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                            <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >

                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}>
                                                            <div data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                                            <h3 style={{ fontWeight: 'bold' }}>Electronic data interchange – EDI   </h3>
                                                            </div>
                                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                                <ul>
                                                                <li style={{ textAlign: 'left' }}> EDI (Electronic Data Interchange) enables the business partners to exchange the documents electronically through various reliable standards.</li>
                                                                <li style={{ textAlign: 'left' }}> Major benefits of EDI includes:-</li>
                                                                <ul style={{ textAlign: 'centre' }}>
                                                                <li style={{ textAlign: 'left' }}> Reduced cost and human efforts</li>
                                                                <li style={{ textAlign: 'left' }}> Speedy and accurate</li>
                                                                <li style={{ textAlign: 'left' }}> Visibility to the transaction status in real time</li>
                                                                <li style={{ textAlign: 'left' }}> Shorten the product delivery Lead time</li>
                                                                <li style={{ textAlign: 'left' }}> Less chances of errors</li>

                                                                </ul></ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="" style={{ width: '100%'}}>
                                                        <img  src={edi_chart} alt="" style={{ width: '80%', height: '80%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                
                                            </div>

                                        <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                                
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                
                                                    <div className=" " style={{ textAlign: 'left', marginLeft: '25px'}}> 
                                                        <div data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                                        <h3 style={{ fontWeight: 'bold' }}>Why EDI </h3>
                                                        </div>
                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                            <li style={{ textAlign: 'left' }}> With EDI services, business data like electronic orders, delivery notes or invoices are linked with your business partners straight from your ERP systems.</li>
                                                            <li style={{ textAlign: 'left' }}> You don’t have to deal with media disruptions.</li>                                     
                                                            <li style={{ textAlign: 'left' }}> We eliminate the hassle of manual data entry</li>
                                                            <li style={{ textAlign: 'left' }}> Integration of data throughout the company reduces processing times and increases data quality.</li>
                                                            <li style={{ textAlign: 'left' }}> This allows you to serve your customers quicker and better.And finally, it saves a huge cost for you</li>                                          

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}>
                                                        <div data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                                        <h3 style={{ fontWeight: 'bold' }}>Our EDI Capabilities </h3>
                                                        </div>
                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                            <li style={{ textAlign: 'left' }}> End to End Implementation of EDI solution: We look after your business data exchange as well as the integration of EDI communication into your ERP system.</li>
                                                            <li style={{ textAlign: 'left' }}> Our EDI experts have many years of experience coupled with ERP integration templates to connect the most wide-ranging ERP systems (ABAS, SAP, QAD, ORACLE apps and many more).</li>
                                                            
                                                            <li style={{ textAlign: 'left' }}> Setups , mapping and testing of EDI between the business partners</li>
                                                            <li style={{ textAlign: 'left' }}> EDI setups and mapping for the ERP system.</li>
                                                            <li style={{ textAlign: 'left' }}> Mapping and support for various EDI standards i.e. ANSI X12, EDIFACT, VDA</li>
                                                            <li style={{ textAlign: 'left' }}> Real time EDI monitoring and support</li>
                                                            <li style={{ textAlign: 'left' }}> User Trainings on EDI transactions</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div> 

                                            <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >                                        
                                                <div className="row">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className=" " style={{ textAlign: 'center', marginLeft: '20px'}}> 
                                                            <div className='row'>
                                                                <div className="col-md-6 col-sm-12">
                                                                    <div className=" " style={{marginTop: '30px'}}><p><img  src={our_team}  alt="" width="65%" height="65%"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-sm-12">

                                                                    <div className="text-container" style={{textAlign: 'center', marginLeft: '0px'}}> 
                                                                        <h3 style={{marginTop: '50px', fontWeight: 'bold'}}>Our Team</h3>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                                <ul>
                                                            
                                                                <li style={{ textAlign: 'left' }}> We are a team of highly motivated and innovative software professionals.</li>
                                                                <li style={{ textAlign: 'left' }}> We strictly follow best industry practices.</li>
                                                                <li style={{ textAlign: 'left' }}> Team of highly trained professionals.</li>                                          
                                                                <li style={{ textAlign: 'left' }}> Highly experienced team with total average experience of more then 11 years.</li>  
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className=" " style={{ textAlign: 'center', marginLeft: '0px'}}>

                                                            <div className='row'>
                                                                <div className="col-md-6 col-sm-12">
                                                                    <div className=" " style={{marginTop: '30px'}}><p><img  src={automation_icon}  alt="" width="55%" height="55%"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-sm-12">
                                                                    <div className="text-container" style={{textAlign: 'center', marginLeft: '0px'}}>                                                          
                                                                        <h3 style={{marginTop: '50px', fontWeight: 'bold'}}>Why We</h3>                                                            
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div style={{ fontWeight: 'normal', marginLeft: '15px' }}>
                                                                <ul>
                                                                
                                                                <li style={{ textAlign: 'left' }}> <b>Competitive:</b> Global company with branches in USA, Germany, Slovakia and India, to offer customized services with regional support.</li>
                                                                <li style={{ textAlign: 'left' }}> <b>Modular Approach:</b> We follow automated processes and offer standardly customized modules. For customers it offer less dependency and faster project execution. Customers can avoid redundancy of modules</li>
                                                                
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                                    <div className="row">

                                                        <div className="col-md-6 col-sm-12">

                                                            <div className=" " style={{textAlign: 'left', marginLeft: '30px'}}>
                                                                <div data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                                                <h3 style={{ fontWeight: 'bold' }}>Development and Customization: </h3>
                                                                </div>
                                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                                    <ul>
                                                                    <li style={{ textAlign: 'left' }}> Turnkey solution through software and automation tailored for your needs.</li>
                                                                    <li style={{ textAlign: 'left' }}> Follow best industry practices</li>                       
                                                                    <li style={{ textAlign: 'left' }}> Help you identify the problem area and building a best solution suited for your need.</li>
                                                                    <li style={{ textAlign: 'left' }}> Follow various Software development models, best suited your requirements.</li>
                                                                    <li style={{ textAlign: 'left' }}> We are a team of highly motivated and innovative software professionals</li>
                                                                
                    
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="" style={{ width: '100%'}}>
                                                                <img  src={dev_cust} alt="" style={{ width: '80%', height: '80%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                            </div>
                                                        </div>

                                                </div>
                                                </div>

                                                <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className=" " style={{ textAlign: 'left', marginLeft: '30px'}}>
                                                                <div data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                                                <h3 style={{ fontWeight: 'bold' }}>Implementation</h3>
                                                                </div>
                                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                                    <ul>
                                                                    <li style={{ textAlign: 'left' }}> The complexity of implementing product software differs on several issues.</li>
                                                                    <li style={{ textAlign: 'left' }}> Examples are: the number of end users that will use the product software, the effects that the implementation has on changes of tasks and responsibilities for the end user, the culture and the integrity of the organization where the software is going to be used and the budget available for acquiring product software.</li>                       
                                                                    <li style={{ textAlign: 'left' }}> It requires in-depth insights on the architecture of the organization as well as of the product itself, before it can be aligned.</li>
                                                                    <li style={{ textAlign: 'left' }}> We help you choose the best implementation methods.</li>
                                                                    <li style={{ textAlign: 'left' }}> Our implementation frameworks serve as the project managed structure to define the implementation phase in time, budget and quality.</li>
                                                                
                    
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                    
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="" style={{ width: '100%'}}>
                                                                <img  src={implementation} alt="" style={{ width: '50%', height: '50%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                            </div>
                                                        </div>

                                                    </div>
                                                
                                                </div>

                                                <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                                    <div className="row">

                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="" style={{alignItems:'center', width: '100%', marginTop: '20px'}}>
                                                                <div data-hspacer="no_spacer" data-halign="Centre" style={{ textAlign: 'left' }}>
                                                                    <h3 style={{ fontWeight: 'bold' }}>Trainings</h3>
                                                                </div>
                                                                <div className="" style={{ width: '100%'}}>
                                                                    <img  src={training_1} alt="" style={{ width: '70%', height: '70%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 col-sm-12">
                                                            <div className=" " style={{ textAlign: 'left', marginLeft: '30px'}}>                                                    
                                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal', marginTop: '70px' }}>
                                                                    <ul>
                                                                    <li style={{ textAlign: 'left' }}> We provide Specific Job Related user trainings.</li>
                                                                    <li style={{ textAlign: 'left' }}> Specific module trainings to the users.</li>                       
                                                                    <li style={{ textAlign: 'left' }}> It helps Employee to do their Current job in best ways.</li>
                                                                    <li style={{ textAlign: 'left' }}> Improve the job knowledge and Skill at level of Organization.</li>
                                                                    <li style={{ textAlign: 'left' }}> Aids in Organization Development.</li>
                                                                    <li style={{ textAlign: 'left' }}> Process handover to trained users.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="" style={{ width: '100%',marginTop:'50px'}}>
                                                                <img  src={training_2} alt="" style={{ width: '70%', height: '70%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>

                                                <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                                    <div className="row">
                                                            <div className="col-md-6 col-sm-12">
                                                                <div className="" style={{alignItems:'center', width: '100%', marginTop: '20px'}}>
                                                                    <div data-hspacer="no_spacer" data-halign="Centre" style={{ textAlign: 'left' }}>
                                                                        <h3 style={{ fontWeight: 'bold' }}>Maintenance</h3>
                                                                    </div>
                                                                    <div className="" style={{ width: '100%'}}>
                                                                        <img  src={maintances} alt="" style={{ width: '60%', height: '60%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                            <div className=" " style={{ textAlign: 'left',marginBottom: '0%', marginLeft: '10px'}}> 
                                                                
                                                                <div style={{marginTop: '70px'}}>
                                                                    <p> Software maintenance in software engineering is the modification of a software product after delivery to correct faults, to improve performance or other attributes.</p>
                                                                    <p> Our Software maintenance services include a broad spectrum of activity that includes:</p>
                                                                    <ul>
                                                            
                                                                    <li> Error correction and bug fixing</li>
                                                                    <li style={{ textAlign: 'left' }}> Enhancements of capabilities,</li>
                                                                    <li style={{ textAlign: 'left' }}> Deletion of obsolete capabilities, and</li>
                                                                    <li style={{ textAlign: 'left' }}> Optimization.</li>
                                                                    </ul>
                                                                    <p>Change is inevitable, hence mechanisms are developed for evaluation, controlling and making modifications.</p>

                                                                </div>

                                                            </div>
                                                            </div>

                                                    </div>
                                                </div>

                                            <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                                <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                                <div className="" style={{alignItems:'center', width: '100%', marginTop: '20px'}}>
                                                                    <div data-hspacer="no_spacer" data-halign="Centre" style={{ textAlign: 'left' }}>
                                                                        <h3 style={{ fontWeight: 'bold' }}>Customer Support</h3>
                                                                    </div>
                                                                    <div className="" style={{ width: '100%'}}>
                                                                        <img  src={cust_support} alt="" style={{ width: '40%', height: '40%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className=" " style={{ textAlign: 'left',marginBottom: '0%', marginLeft: '10px'}}> 
                                                                
                                                                <div style={{marginTop: '70px'}}>
                                                                    <ul>
                                                            
                                                                    <li> We know how important customer support is to your business.</li>
                                                                    <li style={{ textAlign: 'centre' }}> That’s why we’re committed to answering your questions and resolving your issues fast and effectively. Support provides world-class customer support through several different options.</li>
                                                                    <li style={{ textAlign: 'centre' }}> Support options are complemented by an extensive library of detailed online help and other resources such as FAQs, online user guides, sneak peeks at upcoming releases, and tips that will help you get the most from your application.</li>
                                                                    <li style={{ textAlign: 'centre' }}> Helpful, knowledgeable support services when you need it.</li>
                                                                    <li style={{ textAlign: 'centre' }}> Ensuring that you have a reliable support service when you need it is a key element of our support service offering.</li>
                                                                    <li style={{ textAlign: 'centre' }}> You can select product and technical support via phone, email, remote as well as onsite support.</li>
                                                                    <li style={{ textAlign: 'centre' }}> Training and consultation can be on an as-needed fee for service basis or we can tailor a support program specifically for your organization on a committed contract basis..</li>
                                                                    
                                                                    </ul>
                                                                    
                                                                </div>

                                                            </div>
                                                        </div>
                                                </div>
                                            </div>

                                    <div style={{width: '100%', height:'10px',marginTop:'15px', backgroundColor:'#FFFFFF', borderBottom: '5px solid orange', borderBottomWidth: 'thick'}}></div>


                                    <div className='container' style={{ margin:'auto' , textAlign:'center' ,marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                                    <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Projects</h2>
                                    </div>

                                    <div className='container' style={{ margin:'auto' , textAlign:'center' ,marginTop: '0px'}}>
                                                    <h3 style={{ margin:'auto', color:'green' }}>ERP customization Projects</h3>
                                                
                                    </div>


                                        <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}> 
                                                        

                                                        <div className="" style={{marginTop: '30px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                        
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>Data Integration Project</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                        
                                                            <li style={{ textAlign: 'left' }}> Robotic cell layout design</li>
                                                            <li style={{ textAlign: 'left' }}> Gripper (EOAT)selection / design</li>
                                                            <li style={{ textAlign: 'left' }}> Pallet / conveyor design</li>                                          
                                                            <li style={{ textAlign: 'left' }}> Robot Woking simulation on software.</li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="" style={{ textAlign: 'left', marginLeft: '20px'}}>
                                                        <div className="" style={{marginTop: '30px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                            
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>Warehousing Project</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                            
                                                            <li style={{ textAlign: 'left' }}> Developed for German MNC</li>
                                                            <li style={{ textAlign: 'left' }}> We helped develop an integrated warehousing solution for the organization for it’s multiple warehouses spread at various physical locations across Europe.</li>
                                                            
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>


                                        <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="" style={{ textAlign: 'left', marginLeft: '20px'}}>
                                                    <div className="" style={{marginTop: '30px'}}>
                                                        <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                        
                                                            <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>Customized Subcontracting Module</h4>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                        <ul>
                                                        <li style={{ textAlign: 'left' }}>Developed for German MNC</li>
                                                        <li style={{ textAlign: 'left' }}>Subcontracting module is developed to address customer’s issues in sub-contracting such as input, output tracking, packaging material management, subcontractor management etc.</li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="" style={{ textAlign: 'left', marginLeft: '20px'}}>
                                                    <div className="" style={{marginTop: '30px'}}>
                                                        <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                    
                                                            <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>SoD – Segregation of Duties</h4>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                        <ul>
                                                        <li style={{ textAlign: 'left' }}>Developed for German MNC</li>
                                                        <li style={{ textAlign: 'left' }}>SoD project was developed to enhance data security and to monitor the master data changes using 3 eye principle. 3 different layers of segregation were introduced for master date changes.</li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>

                                            </div> 
                                        </div>

                                        <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}> 
                                                        

                                                        <div className="" style={{marginTop: '30px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                            
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>EDI Implementation Project</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                        
                                                            <li style={{ textAlign: 'left' }}> Developed for European customer</li>
                                                            <li style={{ textAlign: 'left' }}> Implemented EDI and integrated it with the existing ERP system.</li>
                                                            <li style={{ textAlign: 'left' }}> Mapping and customization for various customers and suppliers.</li>                                          
                                                            <li style={{ textAlign: 'left' }}> Automation of Order cycle using EDI</li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                        <div className="" style={{marginTop: '0px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                        
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>ShouldB – Engineering Calculation CRM</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                            
                                                            <li style={{ textAlign: 'left' }}> A part manufacturing time and cost estimation and process workflow management tool designed by manufacturing engineers specifically for manufacturing industry.</li>
                                                            
                                                            
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>       
                                        </div>


                                    <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                        <div className="row">

                                            <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}> 
                                                        

                                                        <div className="" style={{marginTop: '30px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                        
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>Web based process calculators</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                        
                                                            <li style={{ textAlign: 'left' }}> The calculators are independent systems that assist in machining time estimation for individual processes.<br></br> All the process calculators can be integrated with the ShouldB system providing a way to calculate the machining cost after the time estimation.</li>
                                                            <li style={{ textAlign: 'left' }}> Grinding Calculator</li>
                                                            <li style={{ textAlign: 'left' }}> Hobbing Calculator</li>                                          
                                                            <li style={{ textAlign: 'left' }}> Broaching Calculator</li>  
                                                            <li style={{ textAlign: 'left' }}> Surface grinding Calculator</li> 
                                                            </ul>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                    <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}>

                                                        <div className="" style={{marginTop: '10px'}}>
                                                            <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                            
                                                                <h4 style={{marginTop: '50px', fontWeight: 'bold'}}>Remote Health Monitoring for Solar Power Plants</h4>
                                                            </div>
                                                        </div>

                                                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                            <ul>
                                                            
                                                            <li style={{ textAlign: 'left' }}> A monitoring and control system for health and environment sensors for solar power plants,</li>
                                                            <li style={{ textAlign: 'left' }}> There are three sets of devices (sensor nodes, routers and coordinator) working independently and communicating with each other.</li>  
                                                            <li style={{ textAlign: 'left' }}> Each sensor node can be attached with 3 fixed and 5 general purpose sensors</li>  
                                                            <li style={{ textAlign: 'left' }}> This system can be accessed using wi-fi and ethernet to graphically view the status and performance as well as upload the data to GeWiS Server (for support purposes).</li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>        
                                    </div>

                                    <div className='container' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} > 
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{ textAlign: 'left', marginLeft: '20px'}}> 
                                                    <div className="" style={{marginTop: '30px'}}>
                                                        <div className="text-container" style={{textAlign: 'left', marginLeft: '30px'}}>
                                                        
                                                            <h4 style={{marginTop: '20px', fontWeight: 'bold'}}>Industry 4.0</h4>
                                                        </div>
                                                    </div>

                                                    <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                        <ul>
                                                    
                                                        <li style={{ textAlign: 'left' }}> Industry 4.0 – Production Machine Monitoring Solutions for Machines with PLC/ CNC.</li>
                                                        <li style={{ textAlign: 'left' }}> Machine communication with Ethernet & HMI for data input by operator.</li>
                                                        <li style={{ textAlign: 'left' }}> Web dashboard / Android app for data representation.</li>                                          
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>  
                                    </div>


                                <div style={{width: '100%', height:'10px',marginTop:'15px', backgroundColor:'#FFFFFF', borderBottom: '5px solid orange', borderBottomWidth: 'thick'}}></div>

                                <div className='container' style={{ margin:'auto' , textAlign:'center' ,marginTop: '30px', marginBottom:'30px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}>
                                                <h2 style={{ margin:'auto', fontWeight: 'bold' }}>Domain Expertise</h2>
                                            </div>
                                       
	

                                        <div className='container' style={{ margin: 'auto', display: 'flex', marginTop: '0px', marginBottom:'10px', height: '250px' }}>
                                            <div className="" style={{ flex: '1', alignItems: 'center',alignContent:'centre', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', marginRight: '10px', height:'100%' }}>
                                                <img src={manufacturing} alt="" style={{ width: '50%', height: '80%',marginLeft:'20%' }} />
                                                <p style={{ margin: '0 10px', textAlign:'center' }}>Manufacturing</p>
                                            </div>

                                            <div className="text-containers" style={{ alignItems: 'center',alignContent:'centre', flex: '1', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', marginRight: '10px', height:'100%' }}>
                                                <img src={automation} alt="" style={{ width: '50%', height: '80%', marginLeft:'20%' }} />
                                                <p style={{ margin: '0 10px', textAlign:'center' }}>Automation</p>
                                            </div>
                                        </div>




                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>






                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;