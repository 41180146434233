import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider from "./../Elements/Slider";
import Specialization from "./../Elements/Specialization";
import About from "./../Elements/About";
import OurValue from "./../Elements/OurValue";
import OurMission from "./../Elements/OurMission";
import OurServices from "./../Elements/OurServices";
import Callus from "./../Elements/Callus";
import OurProject from "./../Elements/OurProject";
import OurProject4 from "./../Elements/OurProject4";
import Specialization3 from "./../Elements/Specialization3";
import Blogs from "./../Elements/Blogs";
import Testimonials from "./../Elements/Testimonials";
import Baani from "../../baani/Baani.js";

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <OurMission />
                    <OurServices />
                    <Specialization3 />
                    <Testimonials />
                </div>
                <Footer />
                <Baani />
            </>
        );
    }
}

export default Home;
