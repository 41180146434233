import React, { Component } from "react";
import PropTypes from "prop-types";
import Option from "./Option";
import OptionElement from "./OptionElement";
import Options from "./Options";
import OptionsStepContainer from "./OptionsStepContainer";
import defaultTheme from "../../theme";

const Globals = require("../../Globals");

class OptionsStep extends Component {
    onOptionClick = ({ value }) => {
        console.log("-- XX-CCCCCC inside onOptionClick. value is: ", value);
        const { triggerNextStep } = this.props;

        triggerNextStep({ value });
    };

    renderOption = (option) => {
        const { bubbleOptionStyle, step } = this.props;
        const { user } = step;
        const { value, label } = option;

        console.log("-- XX-CCCCCC inside renderOption. step is: ", step);

        return (
            <Option key={value} className="rsc-os-option">
                <OptionElement
                    value={value}
                    label={label}
                    onChange={() => this.onOptionClick({ value })}
                />
            </Option>
        );
    };

    render() {
        const { step } = this.props;
        const { options } = step;

        //Ashok added below code on 01-Jun-2023 to ensure submit the audio input when option is displayed but user submits Audio input
        console.log("-- XX-CCCCCC inside render. step is: ", step);
        console.log(
            "-- XX-CCCCCC inside render. Globals.SubmitOptionFlag is: ",
            Globals.SubmitOptionFlag
        );
        console.log(
            "-- XX-CCCCCC inside render.  Globals.SubmitOptionValue is: ",
            Globals.SubmitOptionValue
        );
        console.log(
            "-- XX-CCCCCC inside render.  Globals.skipDisplayOptionFlag is: ",
            Globals.skipDisplayOptionFlag
        );

        if (Globals.SubmitOptionFlag == 1) {
            let tmpValue = Globals.SubmitOptionValue;
            this.onOptionClick({ tmpValue });
        }
        // End of Ashok Step

        if (Globals.skipDisplayOptionFlag == 1) {
            return null;
        }

        return (
            <OptionsStepContainer className="rsc-os">
                <Options className="rsc-os-options">
                    {Object.keys(options)
                        .map((key) => options[key])
                        .map(this.renderOption)}
                </Options>
            </OptionsStepContainer>
        );
    }
}

OptionsStep.propTypes = {
    bubbleOptionStyle: PropTypes.objectOf(PropTypes.any).isRequired,
    step: PropTypes.objectOf(PropTypes.any).isRequired,
    triggerNextStep: PropTypes.func.isRequired,
};

OptionElement.defaultProps = {
    theme: defaultTheme,
};

export default OptionsStep;
