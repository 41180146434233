import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import '../styles.css';

var bnrimg = require('./../../images/banner/manufact1.jpg');

var mecion = require('./../../images/projects/square/me-cion.png');
var right_arrow = require('./../../images/projects/square/right-arrow.png');
var requirement_analysis = require('./../../images/projects/square/requirement-analysis.png');
var arrow_2 = require('./../../images/projects/square/2-arrow.png');
var down_arrow = require('./../../images/projects/square/down-arrow.png');
var left_arrow = require('./../../images/projects/square/left-arrow.png');
var ipresentawtion_to_customermg = require('./../../images/projects/square/presentawtion-to-customer.png');
var project_planning = require('./../../images/projects/square/project-planning.png');
var system_permance_monitoring = require('./../../images/projects/square/system-permance-monitoring.png');
var best_proposal_making = require('./../../images/projects/square/best-proposal-making.png');
var installation_comision = require('./../../images/projects/square/installation-comision.png');
var order_receipt_customer = require('./../../images/projects/square/order-receipt-customer.png');
var drill = require('./../../images/projects/square/drill.png');
var order_prcessing = require('./../../images/projects/square/order-prcessing.png');
var cnc_programming = require('./../../images/projects/square/cnc-programming.png');
var sc_softs = require('./../../images/projects/square/Capture1.PNG');
var sc_softs_2 = require('./../../images/projects/square/Capture2.PNG');
var sc_softs_3 = require('./../../images/projects/square/Capture3.PNG');
var sc_softs_4 = require('./../../images/projects/square/me-5.jpg');
var sc_softs_5 = require('./../../images/projects/square/me-6.jpg');
var sc_softs_6 = require('./../../images/projects/square/me-7.jpg');
var sc_softs_7 = require('./../../images/projects/square/me-8.jpg');
var sc_softs_8 = require('./../../images/projects/square/me-9.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };


        
    





        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Where creativity meets precision, manufacturing engineering comes to life " pagename="Manufacturing Engineering" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                                <div className="container" style={{ marginBottom: '90px', display: 'flex', alignItems: "center",  justifyContent: "center"}}>
                                            <h3 className="blacktext" style={{textAlign: 'center', fontWeight:'normal'}}>
                                                <strong>Process flow – Manufacturing Engineering</strong>
                                            </h3>
                                </div>
                               
                                <div className="container">
                                    <div className="box">
                                        <p><img  src={mecion} alt="" width="180" height="180"  sizes="(max-width: 180px) 100vw, 180px"></img></p>
                                        <p><h5>RFQ & data from customer</h5></p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone size-full wp-image-284" src={arrow_2} alt="" width="113" height="146"></img></div>
                                    </div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-289" src={ipresentawtion_to_customermg}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                    <p>Workout by our ME team</p>
                                    </div>
                                    <div className="box">
                                        <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div></div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-298" src={best_proposal_making} alt="" width="170" height="170" sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Process planning, Cycle time calculation & part costing</p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone right_arrow size-full wp-image-295" src={right_arrow} alt="" width="113" height="69"></img></div>
                                    </div>
                                    <div className="box">
                                        <p><img class="alignnone size-full wp-image-300" src={drill} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Cutting tool selection</p></div>
                                </div>
                                  <div className="container">
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box"></div>
                                    <div className="box" style={{textAlign:'center'}}><div className='second'><img class="alignnone arrow_down size-full wp-image-301" src={down_arrow} alt="" width="70" height="113"></img></div></div>
                                </div>  
                                <div className="container">
                                <div className="box">
                                        <p><img  src={system_permance_monitoring} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                        <p><h5>Process capability monitoring</h5></p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone size-full wp-image-284" src={arrow_2} alt="" width="113" height="146"></img></div>
                                    </div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-289" src={installation_comision}  alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                    <p>Installation & prove out on machine </p>
                                    </div>
                                    <div className="box">
                                        <div class="second"><img class="alignnone size-full wp-image-295" src={left_arrow} alt="" width="113" height="69"></img></div></div>
                                    <div className="box">
                                    <p><img class="alignnone size-full wp-image-298" src={cnc_programming} alt="" width="170" height="170" sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>CNC Programming</p>
                                    </div>
                                    <div className="box">
                                    <div class="second"><img class="alignnone size-full wp-image-295" src={left_arrow} alt="" width="113" height="69"></img></div>
                                    </div>
                                    <div className="box">
                                        <p><img class="alignnone size-full wp-image-300" src={order_prcessing} alt="" width="170" height="170"  sizes="(max-width: 170px) 100vw, 170px"></img></p>
                                            <p>Fixture design</p></div>
                                </div>
                                

                                 
                                    
                            <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="" style={{ width: '100%'}}>
                                        <img  src={sc_softs} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                        <div style={{ textAlign: 'left' }}>
                                        <h3 style={{ fontWeight: 'bold' }}>1.Process Design</h3>
                                        </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Understand the customer infrastructure</li>
                                                <li style={{ textAlign: 'left' }}>Design the best process to get the quality part with the right cost</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_2} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h3 style={{ fontWeight: 'bold' }}>2. Cycle time estimation</h3>
                                                </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}> Selection of right tools to achieve the quality parameters</li>
                                                <li style={{ textAlign: 'left' }}> Estimate the cycle time considering all the cutting parameters</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_3} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h3 style={{ fontWeight: 'bold' }}>3. Manufacturing cost calculation </h3>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}> Raw material cost estimation</li>
                                                <li style={{ textAlign: 'left' }}> Machining cost estimation from calculated cycle times.</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_4} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h3 style={{ fontWeight: 'bold' }}>4. Cutting tool selection   </h3>
                                                </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <ul>
                                                    <li style={{ textAlign: 'left' }}> Selection of right tool to achieve the quality parameters</li>
                                                    <li style={{ textAlign: 'left' }}> Deal with tool suppliers to get best of solutions</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_5} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h3 style={{ fontWeight: 'bold' }}>5.  Holding fixture design  </h3>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}> Designing of holding fixture.</li>
                                                <li style={{ textAlign: 'left' }}> Deal with local tool rooms to manufacture the fixture</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                          
                                            <img  src={sc_softs_6} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h3 style={{ fontWeight: 'bold' }}>6. CNC Programming  </h3>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}> CNC programming considering machine & tools selected.  </li>
                                                <li style={{ textAlign: 'left' }}> Varity of post processors for different types of controls like Fanuc, Siemens etc.  </li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                           
                                            <img  src={sc_softs_7} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h3 style={{ fontWeight: 'bold' }}>7. Installation & Prove out     </h3>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}> Installation of tools, fixtures on your machine.  </li>
                                                <li style={{ textAlign: 'left' }}> Cutting trials on machine for initial parts</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                            
                                            <img  src={sc_softs_8} alt="" style={{ width: '100%', height: '100%' }} sizes="(max-width: 920px) 100vw, 920px"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h3 style={{ fontWeight: 'bold' }}>8. Process capability study </h3>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Process capability & stability study</li>
                                                <li style={{ textAlign: 'left' }}>Handover all equipment's to production after stablility.</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                


                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>






                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;