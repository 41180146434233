const Globals = {
    RecognitionEnableFlag: 0,
    SubmitOptionFlag: 0, //This flag is introduced so that the bot submits the Audio/text input when the option is presented to user
    SubmitOptionValue: "",
    Free_Text_Submitted_For_Option: 0, //This flag is introduced to inform Back end that though the option is presented to the user, it is free text submitted
    skipDisplayOptionFlag: 0, //This flag is introduced to pevent multiple display of options
    Request_Intent_Name: "",
    Request_Intent_Description: "",
    isBaaniSpeaking_Flag: 0,
};
module.exports = Globals;
