import React, { Component } from "react";
import PropTypes from "prop-types";
import Random from "random-id";
import { CustomStep, OptionsStep, TextStep } from "./steps_components";
import schema from "./schemas/schema";
import * as storage from "./storage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
    ChatBotContainer,
    Content,
    Header,
    HeaderTitle,
    HeaderIcon,
    FloatButton,
    FloatingIcon,
    Footer,
    Input,
    SubmitButton,
    VideoBox,
} from "./components";
import Recognition from "./recognition";
import { ChatIcon, CloseIcon, SubmitIcon, MicIcon } from "./icons";
import { isMobile } from "./utils";
import { speakFn } from "./speechSynthesis";
import Player from "./Player.js";
const GlobalStepResponse = require("./GlobalStepResponse");
const GlobaRecognitionValue = require("./GlobaRecognitionValue");
const Globals = require("./Globals");
const Static_globals = require("./Static_globals");
let showOptionFlag = 0;
let snd = require("./snd");
let video_src = "";

class ChatBot extends Component {
    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.content = null;
        this.input = null;

        this.supportsScrollBehavior = false;
        this.submitButtonRef = React.createRef(); // Create a ref to the SubmitButton component Added by Ashok

        this.setContentRef = (element) => {
            this.content = element;
        };

        this.setInputRef = (element) => {
            this.input = element;
        };

        this.state = {
            renderedSteps: [],
            previousSteps: [],
            currentStep: {},
            previousStep: {},
            steps: {},
            disabled: true,
            opened: props.opened || !props.floating,
            inputValue: "",
            inputInvalid: false,
            speaking: false,
            recognitionEnable:
                props.recognitionEnable && Recognition.isSupported(),
            defaultUserSettings: {},
        };

        this.speak = speakFn(props.speechSynthesis);

        this.handlePlayAudio = this.handlePlayAudio.bind(this);
        this.handleAudioEnd = this.handleAudioEnd.bind(this);
    }

    componentDidMount() {
        const { steps } = this.props;
        const {
            botDelay,
            botAvatar,
            botName,
            cache,
            cacheName,
            customDelay,
            enableMobileAutoFocus,
            userAvatar,
            userDelay,
        } = this.props;
        const chatSteps = {};

        const defaultBotSettings = {
            delay: botDelay,
            avatar: botAvatar,
            botName,
        };
        const defaultUserSettings = {
            delay: userDelay,
            avatar: userAvatar,
            hideInput: false,
            hideExtraControl: false,
        };
        const defaultCustomSettings = { delay: customDelay };

        for (let i = 0, len = steps.length; i < len; i += 1) {
            const step = steps[i];
            let settings = {};

            if (step.user) {
                settings = defaultUserSettings;
            } else if (step.message || step.asMessage) {
                settings = defaultBotSettings;
            } else if (step.component) {
                settings = defaultCustomSettings;
            }

            chatSteps[step.id] = Object.assign(
                {},
                settings,
                schema.parse(step)
            );
        }

        schema.checkInvalidIds(chatSteps);

        const firstStep = steps[0];

        if (firstStep.message) {
            const { message } = firstStep;
            firstStep.message =
                typeof message === "function" ? message() : message;
            chatSteps[firstStep.id].message = firstStep.message;
        }

        const { recognitionEnable } = this.state;
        const { recognitionLang } = this.props;

        if (recognitionEnable) {
            this.recognition = new Recognition(
                this.onRecognitionChange,
                this.onRecognitionEnd,
                this.onRecognitionStop,
                this.onRecognitionStart,
                recognitionLang
            );
        }

        this.supportsScrollBehavior =
            "scrollBehavior" in document.documentElement.style;

        if (this.content) {
            this.content.addEventListener(
                "DOMNodeInserted",
                this.onNodeInserted
            );
            window.addEventListener("resize", this.onResize);
        }

        const {
            currentStep,
            previousStep,
            previousSteps,
            renderedSteps,
        } = storage.getData(
            {
                cacheName,
                cache,
                firstStep,
                steps: chatSteps,
            },
            () => {
                // focus input if last step cached is a user step
                this.setState({ disabled: false }, () => {
                    /*if (enableMobileAutoFocus || !isMobile()) {
                        if (this.input) {
                            this.input.focus();
                        }
                    }*/
                });
            }
        );

        // One line below is added by Ashok
        document.addEventListener("AudioPlay", this.handlePlayAudio);
        window.addEventListener("AudioEnd", this.handleAudioEnd);

        this.setState({
            currentStep,
            defaultUserSettings,
            previousStep,
            previousSteps,
            renderedSteps,
            steps: chatSteps,
        });
    }

    static getDerivedStateFromProps(props, state) {
        const { opened, toggleFloating } = props;
        if (
            toggleFloating !== undefined &&
            opened !== undefined &&
            opened !== state.opened
        ) {
            return {
                ...state,
                opened,
            };
        }
        return state;
    }

    componentWillUnmount() {
        if (this.content) {
            this.content.removeEventListener(
                "DOMNodeInserted",
                this.onNodeInserted
            );
            window.removeEventListener("resize", this.onResize);
        }

        // Ashok added this earlier and later commented on 08-May. There is no impact of this
        //window.removeEventListener('AudioEnd', this.handleAudioEnd);
    }

    onNodeInserted = (event) => {
        const { currentTarget: target } = event;
        const { enableSmoothScroll } = this.props;

        if (enableSmoothScroll && this.supportsScrollBehavior) {
            target.scroll({
                top: target.scrollHeight,
                left: 0,
                behavior: "smooth",
            });
        } else {
            target.scrollTop = target.scrollHeight;
        }
    };

    onResize = () => {
        this.content.scrollTop = this.content.scrollHeight;
    };

    onRecognitionChange = (value) => {
        console.log(
            "--XX-AAAAA-ChatBot.jsx onRecognitionChange. value is ",
            value
        );
        this.setState({ inputValue: value });
    };

    onRecognitionEnd = (event) => {
        console.log(
            "--XX-AAAAA-ChatBot.jsx onRecognitionEnd.  event is: ",
            event
        );
        console.log(
            "--XX-AAAAA-ChatBot.jsx onRecognitionEnd. setting the value of speaking to false. inputValue is: ",
            this.state.inputValue
        );

        this.setState({ speaking: false });
        Globals.RecognitionEnableFlag = 0;

        console.log(
            "--XX-AAAAA- options_flag is: ",
            GlobalStepResponse.options_flag
        );
        // Below two lines added by Ashok on 09-Jun-23
        if (
            !GlobaRecognitionValue.RecognitionValue.trim() &&
            GlobalStepResponse.options_flag == 0
        ) {
            return;
        }

        this.setState({ inputValue: GlobaRecognitionValue.RecognitionValue }); //Added by Ashok on 06-May
        console.log(
            "--XX-AAAAA-ChatBot.jsx onRecognitionEnd. inputValue is: ",
            this.state.inputValue
        );

        if (this.recognition) {
            //this.recognition.disposeRecognition(); //Commented by Ashok on 30-May-23
        }
        if (isMobile()) {
            /*console.log(
                "--XX-AAAAA-ChatBot.jsx onRecognitionEnd, inside isMobile. "
            );*/
            //Added for Android on 04-May
            //this.handleSubmitButton(); //Commented by Ashok on 23-April
            //this.submitUserMessage();
        }

        //The line below is added by Ashok on 07-May
        if (this.state.inputValue.trim()) {
            this.submitUserMessage();
        }

        // Add a delay of 500ms before submitting the user message
        /*setTimeout(() => {
            this.submitUserMessage();
        }, 2000); */
    };

    onRecognitionStop = () => {
        console.log("--XX-AAAAA-ChatBot.jsx onRecognitionStop. ");
        this.setState({ speaking: false });
    };

    onValueChange = (event) => {
        console.log("--XX-AAAAA-ChatBot.jsx onValueChange. event is: ", event);
        this.setState({ inputValue: event.target.value });
    };

    getTriggeredStep = (trigger, value) => {
        console.log("--XX-AAAAA getTriggeredStep");
        const steps = this.generateRenderedStepsById();
        return typeof trigger === "function"
            ? trigger({ value, steps })
            : trigger;
    };

    getStepMessage = (message) => {
        console.log("--XX-AAAAA getStepMessage");
        const { previousSteps } = this.state;
        const lastStepIndex =
            previousSteps.length > 0 ? previousSteps.length - 1 : 0;
        const steps = this.generateRenderedStepsById();
        const previousValue = previousSteps[lastStepIndex].value;
        return typeof message === "function"
            ? message({ previousValue, steps })
            : message;
    };

    generateRenderedStepsById = () => {
        console.log("--XX-AAAAA generateRenderedStepsById");
        const { previousSteps } = this.state;
        const steps = {};

        for (let i = 0, len = previousSteps.length; i < len; i += 1) {
            const { id, message, value, metadata } = previousSteps[i];

            steps[id] = {
                id,
                message,
                value,
                metadata,
            };
        }

        console.log("--XX-AAAAA End of generateRenderedStepsById");
        return steps;
    };

    triggerNextStep = (data) => {
        console.log("--XX-AAAAA triggerNextStep data is: ", data);

        const { enableMobileAutoFocus } = this.props;
        const {
            defaultUserSettings,
            previousSteps,
            renderedSteps,
            steps,
        } = this.state;

        let { currentStep, previousStep } = this.state;
        const isEnd = currentStep.end;

        if (data && data.value) {
            currentStep.value = data.value;
        }
        if (data && data.hideInput) {
            currentStep.hideInput = data.hideInput;
        }
        if (data && data.hideExtraControl) {
            currentStep.hideExtraControl = data.hideExtraControl;
        }
        if (data && data.trigger) {
            currentStep.trigger = this.getTriggeredStep(
                data.trigger,
                data.value
            );
        }

        console.log(
            "-XX-AAAAA triggerNextStep. Globals.SubmitOptionFlag is: ",
            Globals.SubmitOptionFlag
        );
        console.log(
            "-XX-AAAAA triggerNextStep. Globals.SubmitOptionValue is: ",
            Globals.SubmitOptionValue
        );

        if (isEnd && Globals.SubmitOptionFlag == 0) {
            this.handleEnd();
        } else if (
            currentStep.options &&
            data &&
            Globals.SubmitOptionFlag == 0
        ) {
            const option = currentStep.options.filter(
                (o) => o.value === data.value
            )[0];
            const trigger = this.getTriggeredStep(
                option.trigger,
                currentStep.value
            );
            delete currentStep.options;

            // replace choose option for user message
            currentStep = Object.assign(
                {},
                currentStep,
                option,
                defaultUserSettings,
                {
                    user: true,
                    message: option.label,
                    trigger,
                }
            );

            renderedSteps.pop();
            previousSteps.pop();
            renderedSteps.push(currentStep);
            previousSteps.push(currentStep);

            this.setState({
                currentStep,
                renderedSteps,
                previousSteps,
            });
            // this.startRecognition(); //Added by ashok on 31-May-23
        } else if (currentStep.trigger && Globals.SubmitOptionFlag == 0) {
            if (currentStep.replace) {
                renderedSteps.pop();
            }

            /*const trigger = this.getTriggeredStep(
                currentStep.trigger,
                currentStep.value
            ); */

            /*The 1 statement commented above will be replaced with the new custom code by Ashok */

            let trigger = this.getTriggeredStep(
                currentStep.trigger,
                currentStep.value
            );
            console.log("?? trigger: ", trigger);

            if (GlobalStepResponse.options_flag == 1) {
                trigger = this.getTriggeredStep(
                    "optionsStep",
                    currentStep.value
                );

                steps[trigger].options.splice(
                    0,
                    steps[trigger].options.length,
                    ...GlobalStepResponse.option_values
                );
                showOptionFlag = 0;
                GlobalStepResponse.options_flag = 0;

                console.log("?? 2. trigger: ", trigger);
            }

            let nextStep = Object.assign({}, steps[trigger]);

            if (nextStep.message) {
                nextStep.message = this.getStepMessage(nextStep.message);
            } else if (nextStep.update) {
                const updateStep = nextStep;
                nextStep = Object.assign({}, steps[updateStep.update]);

                if (nextStep.options) {
                    for (
                        let i = 0, len = nextStep.options.length;
                        i < len;
                        i += 1
                    ) {
                        nextStep.options[i].trigger = updateStep.trigger;
                    }
                } else {
                    nextStep.trigger = updateStep.trigger;
                }
            }

            nextStep.key = Random(24);

            previousStep = currentStep;
            currentStep = nextStep;

            this.setState({ renderedSteps, currentStep, previousStep }, () => {
                if (nextStep.user) {
                    this.setState({ disabled: false }, () => {
                        /*if (enableMobileAutoFocus || !isMobile()) {
                            if (this.input) {
                                this.input.focus();
                            }
                        }*/
                    });
                } else {
                    renderedSteps.push(nextStep);
                    previousSteps.push(nextStep);

                    this.setState({ renderedSteps, previousSteps });
                }
            });
        }
        //Ashok Added the below code on 02-June-2023 to ensure it allows to submit Audio when Option is displayed
        if (Globals.SubmitOptionFlag == 1) {
            console.log(
                "-XX-AAAAA triggerNextStep. Evaluating the Option submit Logic. Globals.SubmitOptionFlag is: ",
                Globals.SubmitOptionFlag
            );
            console.log(
                "-XX-AAAAA Before modification previousStep step is: ",
                previousStep
            );
            console.log(
                "-XX-AAAAA Before modification current step is: ",
                currentStep
            );

            Globals.SubmitOptionFlag = 0;

            // Ashok added this on 04-Jun-23
            let tmpPreviousTrigger = this.getTriggeredStep(
                "search",
                Globals.SubmitOptionValue
            );
            console.log("-XX-AAAAA tmpPreviousTrigger: ", tmpPreviousTrigger);
            let tmpPreviousStep = Object.assign({}, steps[tmpPreviousTrigger]);
            tmpPreviousStep.message = Globals.SubmitOptionValue;
            tmpPreviousStep.key = Random(24);
            console.log("-XX-AAAAA tmpPreviousStep: ", tmpPreviousStep);
            //End of 04-Jun

            let trigger = this.getTriggeredStep(
                "getResult",
                Globals.SubmitOptionValue
            );
            console.log("-XX-AAAAA trigger: ", trigger);

            let nextStep = Object.assign({}, steps[trigger]);

            nextStep.message = this.getStepMessage(nextStep.message);

            nextStep.key = Random(24);

            console.log("-XX-AAAAA Next step is: ", nextStep);

            //previousStep = tmpPreviousStep; //Changed by Ashok on 04-Jun-23
            this.setState({ previousStep, tmpPreviousStep });
            currentStep = nextStep;

            console.log(
                "-XX-AAAAA Before Setting state previousStep is: ",
                previousStep
            );
            console.log(
                "-XX-AAAAA Before Setting state currentStep is: ",
                currentStep
            );
            console.log(
                "-XX-AAAAA Before Setting state renderedSteps is: ",
                renderedSteps
            );

            this.setState({ renderedSteps, currentStep, previousStep }, () => {
                console.log("-XX-AAAAA nextStep.user is: ", nextStep.user);
                if (nextStep.user) {
                    this.setState({ disabled: false }, () => {});
                } else {
                    Globals.Free_Text_Submitted_For_Option = 1;
                    console.log(
                        "--Inside setState Free_Text_Submitted_For_Option",
                        Globals.Free_Text_Submitted_For_Option
                    );
                    renderedSteps.pop();
                    renderedSteps.push(previousStep);
                    previousSteps.push(currentStep);

                    this.setState({ renderedSteps, previousSteps });
                }
            });
            console.log(
                "-XX-AAAAA After modified previousStep step is: ",
                previousStep
            );
            console.log(
                "-XX-AAAAA After modified current step is: ",
                currentStep
            );
            console.log(
                "-XX-AAAAA After modified renderedSteps is: ",
                renderedSteps
            );
        } else {
            Globals.SubmitOptionValue = "";
        }
        //End of Ashoks Code

        console.log("-XX-AAAAA Final previousStep is: ", previousStep);
        console.log("-XX-AAAAA Final currentStep is: ", currentStep);
        console.log("-XX-AAAAA After Final renderedSteps is: ", renderedSteps);

        const { cache, cacheName } = this.props;
        if (cache) {
            setTimeout(() => {
                storage.setData(cacheName, {
                    currentStep,
                    previousStep,
                    previousSteps,
                    renderedSteps,
                });
            }, 300);
        }
    };

    /*handlePlayAudio = (event, data) => {
        console.log("-- handlePlayAudio CALLED");

        video_src = event.detail.video_src;
        snd.src = video_src;
        snd.play();
    }; */

    handlePlayAudio = (event, data) => {
        console.log("-- handlePlayAudio CALLED");

        video_src = event.detail.video_src;

        console.log("-- video_src is: ", video_src);
        snd.src = video_src;

        snd.onended = () => {
            const audioEndEvent = new Event("AudioEnd");
            Globals.isBaaniSpeaking_Flag = 0;
            window.dispatchEvent(audioEndEvent);
        };

        this.setState({ placeholder: "Baani Speaking!!" });

        Globals.isBaaniSpeaking_Flag = 1;
        snd.play();
    };

    //The below function is added by Ashok
    //This function is called at the end of Video to check if there is any proactive video needs to be played
    handleAudioEnd = () => {
        console.log("?? Catched the video end event. handleAudioEnd");

        let data = null;
        // Ashok commented the one line below and added 2 lines on 08-May. They seems to be working fine
        // this.submitButtonRef.current.click(); // Trigger the SubmitButton click event. Added by Ashok
        //this.recognition.speak();

        //Ashok comented  below 2 lines on 08-Jun-23
        //this.startRecognition(); //Ashok commented on 10-May these two lines as these lines causes the video to be duplicated on ios
        //this.setState({ speaking: true });

        // The logic below ensure that the logic of proactive message is not initiated if this is not proactive messhae. We are not going to start recognition if this is proactive response
        if (GlobalStepResponse.transition_flag == 0) {
            this.startRecognition(); //Ashok added this here on 08-Jun-23
            this.setState({ speaking: true }); //Ashok added this here on 08-Jun-23
            return;
        }

        const { enableMobileAutoFocus } = this.props;
        const {
            defaultUserSettings,
            previousSteps,
            renderedSteps,
            steps,
        } = this.state;

        let { currentStep, previousStep } = this.state;

        //Ashok commented one line below on 10-May. Not needed
        //const isEnd = currentStep.end;

        if (currentStep.trigger) {
            if (currentStep.replace) {
                renderedSteps.pop();
            }

            /*const trigger = this.getTriggeredStep(
                currentStep.trigger,
                currentStep.value
            ); */

            /*The 1 statement commented above will be replaced with the new custom code by Ashok */

            let trigger = this.getTriggeredStep(
                currentStep.trigger,
                currentStep.value
            );
            console.log("?? trigger: ", trigger);

            if (
                GlobalStepResponse.transition_flag == 1 &&
                GlobalStepResponse.transition_processed_flag == 0
            ) {
                trigger = this.getTriggeredStep(
                    "getResult",
                    GlobalStepResponse.next_state.next_state_response
                );

                //steps[trigger].search.value = GlobalStepResponse.next_state.next_state_response;

                //GlobalStepResponse.transition_flag = 0;

                console.log("?? 3. trigger: ", trigger);
                console.log("?? 3. steps is: ", steps);

                GlobalStepResponse.transition_processed_flag = 1;
            }

            /*End of custom code Ashok */

            let nextStep = Object.assign({}, steps[trigger]);

            if (nextStep.message) {
                nextStep.message = this.getStepMessage(nextStep.message);
            } else if (nextStep.update) {
                const updateStep = nextStep;
                nextStep = Object.assign({}, steps[updateStep.update]);

                if (nextStep.options) {
                    for (
                        let i = 0, len = nextStep.options.length;
                        i < len;
                        i += 1
                    ) {
                        nextStep.options[i].trigger = updateStep.trigger;
                    }
                } else {
                    nextStep.trigger = updateStep.trigger;
                }
            }

            nextStep.key = Random(24);

            previousStep = currentStep;
            currentStep = nextStep;

            this.setState({ renderedSteps, currentStep, previousStep }, () => {
                if (nextStep.user) {
                    this.setState({ disabled: false }, () => {
                        /*if (enableMobileAutoFocus || !isMobile()) {
                            if (this.input) {
                                this.input.focus();
                            }
                        } */
                    });
                } else {
                    renderedSteps.push(nextStep);
                    previousSteps.push(nextStep);

                    this.setState({ renderedSteps, previousSteps });
                }
            });
        }

        // Ashok commented the one line below  on 08-May. They seems to be working fine
        /*const { cache, cacheName } = this.props;
        if (cache) {
            setTimeout(() => {
                storage.setData(cacheName, {
                    currentStep,
                    previousStep,
                    previousSteps,
                    renderedSteps,
                });
            }, 300);
        } */
    };

    handleEnd = () => {
        console.log("--XX-AAAAA handleEnd");
        const { handleEnd } = this.props;

        if (handleEnd) {
            const { previousSteps } = this.state;

            const renderedSteps = previousSteps.map((step) => {
                const { id, message, value, metadata } = step;

                return {
                    id,
                    message,
                    value,
                    metadata,
                };
            });

            const steps = [];

            for (let i = 0, len = previousSteps.length; i < len; i += 1) {
                const { id, message, value, metadata } = previousSteps[i];

                steps[id] = {
                    id,
                    message,
                    value,
                    metadata,
                };
            }

            const values = previousSteps
                .filter((step) => step.value)
                .map((step) => step.value);

            handleEnd({ renderedSteps, steps, values });
        }
    };

    isInputValueEmpty = () => {
        console.log("--XX-AAAAA isInputValueEmpty");
        const { inputValue } = this.state;
        console.log("--XX-AAAAA inputValue is: ", inputValue);
        return !inputValue || inputValue.length === 0;
    };

    isLastPosition = (step) => {
        console.log("--XX-AAAAA isLastPosition");
        const { renderedSteps } = this.state;
        const { length } = renderedSteps;
        const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

        if (length <= 1 || stepIndex + 1 === length) {
            return true;
        }

        const nextStep = renderedSteps[stepIndex + 1];
        const hasMessage = nextStep.message || nextStep.asMessage;

        if (!hasMessage) {
            return true;
        }

        const isLast = step.user !== nextStep.user;
        return isLast;
    };

    isFirstPosition = (step) => {
        console.log("--XX-AAAAA isFirstPosition");
        const { renderedSteps } = this.state;
        const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

        if (stepIndex === 0) {
            return true;
        }

        const lastStep = renderedSteps[stepIndex - 1];
        const hasMessage = lastStep.message || lastStep.asMessage;

        if (!hasMessage) {
            return true;
        }

        const isFirst = step.user !== lastStep.user;
        return isFirst;
    };
    handleKeyPress = (event) => {
        console.log("--XX-AAAAA handleKeyPress");
        if (event.key === "Enter") {
            this.submitUserMessage();
        }
    };

    handleSubmitButton = () => {
        console.log("--XX-AAAAA-ChatBot.jsx Inside handleSubmitButton Start.");
        const { speaking, recognitionEnable } = this.state;

        if (snd == "") {
            snd = new Audio();
        }

        // This if statement is moved on top on 24-Jun-23
        if (this.state.inputValue.trim()) {
            this.recognition.stopRecognition();
            this.submitUserMessage();
        } else {
            if (Globals.RecognitionEnableFlag == 0) {
                Globals.RecognitionEnableFlag = 1;
                this.setState({ speaking: true });
                console.log(
                    "--XX-AAAAA- Inside handleSubmitButton Starting Recognition."
                );
                this.recognition.speak();
            } else if (
                (this.isInputValueEmpty() || speaking) &&
                recognitionEnable
            ) {
                console.log(
                    "--XX-AAAAA-ChatBot.jsx Inside handleSubmitButton going to start speak."
                );

                /*this.recognition.speak();
            if (!speaking) {
                console.log(
                    "--XX-AAAAA-ChatBot.jsx Inside handleSubmitButton User is not speaking and therefore setting state speaking true."
                );
                this.setState({ speaking: true });
            }  */
                //return;
            }

            console.log(
                "--XX-AAAAA-ChatBot.jsx Inside handleSubmitButton Mid."
            );
        }
        //this.submitUserMessage(); //Ashok commented this on 10-May as this has no impact
        //this.startRecognition();   //Ashok commented this on 10-May as this has no impact

        console.log("--XX-AAAAA-ChatBot.jsx Inside handleSubmitButton End.");
    };

    //This method is added by Ashok
    startRecognition = () => {
        console.log("--XX-AAAAA-ChatBot.jsx startRecognition. ");

        const { recognitionEnable } = this.state;
        const { recognitionLang } = this.props;

        this.recognition = new Recognition(
            this.onRecognitionChange,
            this.onRecognitionEnd,
            this.onRecognitionStop,
            this.onRecognitionStart,
            recognitionLang
        );

        const { recognition } = this;

        // Reset the input value
        this.setState({ inputValue: "" });

        // Start the recognition engine
        recognition.speak();
        recognition.onresult = this.onRecognitionResult; //Commented by Ashok on 04-May
    };

    submitUserMessage = () => {
        console.log("--XX-AAAAA-ChatBot.jsx submitUserMessage.");

        //Ashok added below on 18-June-23 to fix the text input issue
        if (snd == "") {
            snd = new Audio();
        }

        const {
            defaultUserSettings,
            inputValue,
            previousSteps,
            renderedSteps,
        } = this.state;

        console.log(
            "--XX-AAAAA-ChatBot.jsx submitUserMessage. inputValue is: ",
            inputValue
        );

        this.recognition.disposeRecognition();
        this.recognition = null;

        let { currentStep } = this.state;

        //Ashok added below on 01-Jun-2023
        console.log(
            "--XXXXXXX-AAAAA-ChatBot.jsx submitUserMessage. currentStep is: ",
            currentStep
        );
        if (currentStep.options) {
            console.log(
                "--XXXXXXX-AAAAA-ChatBot.jsx currentStep.options. currentStep is: ",
                currentStep
            );
            Globals.SubmitOptionFlag = 1;
            Globals.skipDisplayOptionFlag = 1;

            Globals.SubmitOptionValue = inputValue;
        }

        const isInvalid = currentStep.validator && this.checkInvalidInput();

        if (!isInvalid) {
            const step = {
                message: inputValue,
                value: inputValue,
            };

            currentStep = Object.assign(
                {},
                defaultUserSettings,
                currentStep,
                step
            );

            renderedSteps.push(currentStep);
            previousSteps.push(currentStep);

            this.setState(
                {
                    currentStep,
                    renderedSteps,
                    previousSteps,
                    disabled: true,
                    inputValue: "",
                },
                () => {
                    if (this.input) {
                        this.input.blur();
                    }
                }
            );
        }
    };

    checkInvalidInput = () => {
        console.log("--XX-AAAAA checkInvalidInput");
        const { enableMobileAutoFocus } = this.props;
        const { currentStep, inputValue } = this.state;
        const result = currentStep.validator(inputValue);
        const value = inputValue;

        if (typeof result !== "boolean" || !result) {
            this.setState(
                {
                    inputValue: result.toString(),
                    inputInvalid: true,
                    disabled: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState(
                            {
                                inputValue: value,
                                inputInvalid: false,
                                disabled: false,
                            },
                            () => {
                                /*if (enableMobileAutoFocus || !isMobile()) {
                                    if (this.input) {
                                        this.input.focus();
                                    }
                                }*/
                            }
                        );
                    }, 500);
                }
            );

            return true;
        }

        return false;
    };

    toggleChatBot = (opened) => {
        console.log("--XX-AAAAA toggleChatBot");
        const { toggleFloating } = this.props;

        if (toggleFloating) {
            toggleFloating({ opened });
        } else {
            this.setState({ opened });
        }
    };

    renderStep = (step, index) => {
        console.log("--XX-AAAAA renderStep");
        const { renderedSteps } = this.state;
        const {
            avatarStyle,
            bubbleStyle,
            bubbleOptionStyle,
            customStyle,
            hideBotAvatar,
            hideUserAvatar,
            speechSynthesis,
        } = this.props;
        const { options, component, asMessage } = step;
        const steps = this.generateRenderedStepsById();
        const previousStep = index > 0 ? renderedSteps[index - 1] : {};

        if (component && !asMessage) {
            return (
                <CustomStep
                    key={index}
                    speak={this.speak}
                    step={step}
                    steps={steps}
                    style={customStyle}
                    previousStep={previousStep}
                    previousValue={previousStep.value}
                    triggerNextStep={this.triggerNextStep}
                />
            );
        }

        if (options) {
            return (
                <OptionsStep
                    key={index}
                    step={step}
                    previousValue={previousStep.value}
                    triggerNextStep={this.triggerNextStep}
                    bubbleOptionStyle={bubbleOptionStyle}
                />
            );
        }

        return (
            <TextStep
                key={index}
                step={step}
                steps={steps}
                speak={this.speak}
                previousStep={previousStep}
                previousValue={previousStep.value}
                triggerNextStep={this.triggerNextStep}
                avatarStyle={avatarStyle}
                bubbleStyle={bubbleStyle}
                hideBotAvatar={hideBotAvatar}
                hideUserAvatar={hideUserAvatar}
                speechSynthesis={speechSynthesis}
                isFirst={this.isFirstPosition(step)}
                isLast={this.isLastPosition(step)}
            />
        );
    };

    render() {
        console.log("--XX-AAAAA render() is called");
        const {
            currentStep,
            disabled,
            inputInvalid,
            inputValue,
            opened,
            renderedSteps,
            speaking,
            recognitionEnable,
        } = this.state;
        const {
            className,
            contentStyle,
            extraControl,
            controlStyle,
            floating,
            floatingIcon,
            floatingStyle,
            footerStyle,
            headerComponent,
            headerTitle,
            hideHeader,
            hideSubmitButton,
            inputStyle,
            placeholder,
            inputAttributes,
            recognitionPlaceholder,
            style,
            submitButtonStyle,
            width,
            height,
        } = this.props;

        const header = headerComponent || (
            <Header className="rsc-header">
                <HeaderTitle className="rsc-header-title">
                    {headerTitle}
                </HeaderTitle>
                {floating && (
                    <HeaderIcon
                        className="rsc-header-close-button"
                        onClick={() => this.toggleChatBot(false)}
                    >
                        <CloseIcon />
                    </HeaderIcon>
                )}
            </Header>
        );

        let customControl;
        if (extraControl !== undefined) {
            customControl = React.cloneElement(extraControl, {
                disabled,
                speaking,
                invalid: inputInvalid,
            });
        }

        const icon =
            (this.isInputValueEmpty() || speaking) && recognitionEnable ? (
                <MicIcon />
            ) : (
                <SubmitIcon />
            );

        let inputPlaceholder = speaking
            ? recognitionPlaceholder
            : currentStep.placeholder || placeholder;

        //Added by Ashok on 09-Jun-23 to display Baani Speaking in Recognition placeholder
        if (Globals.isBaaniSpeaking_Flag == 1) {
            inputPlaceholder = "Baani Speaking!!";
        }

        const inputAttributesOverride =
            currentStep.inputAttributes || inputAttributes;

        return (
            <div className={`rsc ${className}`}>
                {floating && (
                    <FloatButton
                        className="rsc-float-button"
                        style={floatingStyle}
                        opened={opened}
                        onClick={() => this.toggleChatBot(true)}
                    >
                        {typeof floatingIcon === "string" ? (
                            <FloatingIcon src={floatingIcon} />
                        ) : (
                            floatingIcon
                        )}
                    </FloatButton>
                )}
                <HelmetProvider>
                    <Helmet>
                        <title>{Static_globals.Helmet_page_title} </title>
                        <meta
                            name="description"
                            content={Static_globals.Helmet_page_content}
                        />
                        <link
                            rel="canonical"
                            href={Static_globals.Helmet_href}
                        />
                    </Helmet>
                    <ChatBotContainer
                        className="rsc-container"
                        floating={floating}
                        floatingStyle={floatingStyle}
                        opened={opened}
                        style={style}
                        width={width}
                        height={height}
                    >
                        {!hideHeader && header}
                        <VideoBox>
                            <Player />
                        </VideoBox>

                        <Content
                            className="rsc-content"
                            ref={this.setContentRef}
                            floating={floating}
                            style={contentStyle}
                            height={height}
                            hideInput={currentStep.hideInput}
                        >
                            {renderedSteps.map(this.renderStep)}
                        </Content>
                        <Footer className="rsc-footer" style={footerStyle}>
                            {!currentStep.hideInput && (
                                <Input
                                    type="textarea"
                                    style={inputStyle}
                                    ref={this.setInputRef}
                                    className="rsc-input"
                                    placeholder={
                                        inputInvalid ? "" : inputPlaceholder
                                    }
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.onValueChange}
                                    value={inputValue}
                                    floating={floating}
                                    invalid={inputInvalid}
                                    disabled={disabled}
                                    hasButton={!hideSubmitButton}
                                    {...inputAttributesOverride}
                                />
                            )}
                            <div style={controlStyle} className="rsc-controls">
                                {!currentStep.hideInput &&
                                    !currentStep.hideExtraControl &&
                                    customControl}
                                {!currentStep.hideInput && !hideSubmitButton && (
                                    <SubmitButton
                                        className="rsc-submit-button"
                                        style={submitButtonStyle}
                                        onClick={this.handleSubmitButton}
                                        invalid={inputInvalid}
                                        disabled={disabled}
                                        speaking={speaking}
                                        ref={this.submitButtonRef} // Add a ref to the SubmitButton component Ashok added this line
                                    >
                                        {icon}
                                    </SubmitButton>
                                )}
                            </div>
                        </Footer>
                    </ChatBotContainer>
                </HelmetProvider>
            </div>
        );
    }
}

ChatBot.propTypes = {
    avatarStyle: PropTypes.objectOf(PropTypes.any),
    botAvatar: PropTypes.string,
    botName: PropTypes.string,
    botDelay: PropTypes.number,
    bubbleOptionStyle: PropTypes.objectOf(PropTypes.any),
    bubbleStyle: PropTypes.objectOf(PropTypes.any),
    cache: PropTypes.bool,
    cacheName: PropTypes.string,
    className: PropTypes.string,
    contentStyle: PropTypes.objectOf(PropTypes.any),
    customDelay: PropTypes.number,
    customStyle: PropTypes.objectOf(PropTypes.any),
    controlStyle: PropTypes.objectOf(PropTypes.any),
    enableMobileAutoFocus: PropTypes.bool,
    enableSmoothScroll: PropTypes.bool,
    extraControl: PropTypes.objectOf(PropTypes.element),
    floating: PropTypes.bool,
    floatingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    floatingStyle: PropTypes.objectOf(PropTypes.any),
    footerStyle: PropTypes.objectOf(PropTypes.any),
    handleEnd: PropTypes.func,
    headerComponent: PropTypes.element,
    headerTitle: PropTypes.string,
    height: PropTypes.string,
    hideBotAvatar: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideSubmitButton: PropTypes.bool,
    hideUserAvatar: PropTypes.bool,
    inputAttributes: PropTypes.objectOf(PropTypes.any),
    inputStyle: PropTypes.objectOf(PropTypes.any),
    opened: PropTypes.bool,
    toggleFloating: PropTypes.func,
    placeholder: PropTypes.string,
    recognitionEnable: PropTypes.bool,
    recognitionLang: PropTypes.string,
    recognitionPlaceholder: PropTypes.string,
    speechSynthesis: PropTypes.shape({
        enable: PropTypes.bool,
        lang: PropTypes.string,
        voice:
            typeof window !== "undefined"
                ? PropTypes.instanceOf(window.SpeechSynthesisVoice)
                : PropTypes.any,
    }),
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
    style: PropTypes.objectOf(PropTypes.any),
    submitButtonStyle: PropTypes.objectOf(PropTypes.any),
    userAvatar: PropTypes.string,
    userDelay: PropTypes.number,
    width: PropTypes.string,
};

ChatBot.defaultProps = {
    avatarStyle: {},
    botDelay: 100,
    botName: "The bot",
    bubbleOptionStyle: {},
    bubbleStyle: {},
    cache: false,
    cacheName: "rsc_cache",
    className: "",
    contentStyle: {},
    customStyle: {},
    controlStyle: { position: "absolute", right: "0", top: "0" },
    customDelay: 100,
    enableMobileAutoFocus: false,
    enableSmoothScroll: false,
    extraControl: undefined,
    floating: false,
    floatingIcon: <ChatIcon />,
    floatingStyle: {},
    footerStyle: {},
    handleEnd: undefined,
    headerComponent: undefined,
    headerTitle: "Chat",
    height: "520px",
    hideBotAvatar: false,
    hideHeader: false,
    hideSubmitButton: false,
    hideUserAvatar: false,
    inputStyle: {},
    opened: undefined,
    placeholder: "Enable the recognition and speak...",
    inputAttributes: {},
    recognitionEnable: false,
    recognitionLang: "en",
    recognitionPlaceholder: "Listening ...",
    speechSynthesis: {
        enable: false,
        lang: "en",
        voice: null,
    },
    style: {},
    submitButtonStyle: {},
    toggleFloating: undefined,
    userDelay: 100,
    width: "350px",
    botAvatar:
        "data:image/svg+xml,%3csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M303 70a47 47 0 1 0-70 40v84h46v-84c14-8 24-23 24-40z' fill='%2393c7ef'/%3e%3cpath d='M256 23v171h23v-84a47 47 0 0 0-23-87z' fill='%235a8bb0'/%3e%3cpath fill='%2393c7ef' d='M0 240h248v124H0z'/%3e%3cpath fill='%235a8bb0' d='M264 240h248v124H264z'/%3e%3cpath fill='%2393c7ef' d='M186 365h140v124H186z'/%3e%3cpath fill='%235a8bb0' d='M256 365h70v124h-70z'/%3e%3cpath fill='%23cce9f9' d='M47 163h419v279H47z'/%3e%3cpath fill='%2393c7ef' d='M256 163h209v279H256z'/%3e%3cpath d='M194 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%233c5d76'/%3e%3cpath d='M380 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%231e2e3b'/%3e%3cpath d='M186 349a70 70 0 1 0 140 0H186z' fill='%233c5d76'/%3e%3cpath d='M256 349v70c39 0 70-31 70-70h-70z' fill='%231e2e3b'/%3e%3c/svg%3e",
    userAvatar:
        "data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e",
};

export default ChatBot;
